import { Chart as ChartJS, ArcElement } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React, { useMemo, useCallback } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { vendorsColors } from '../../helpers/helpers';
ChartJS.register(ArcElement);
const DoughnutChart = ({ cashRevenue, integrationsRevenueByVendor }) => {
    const percentagesCashRevenue = useMemo(() => {
        if (!integrationsRevenueByVendor.length || Math.abs(cashRevenue) === 0)
            return [];
        const percentages = integrationsRevenueByVendor.map((vendor) => Math.round((vendor.cashRevenue / Math.abs(cashRevenue)) * 100));
        const diffPercentages = 100 - percentages.reduce((acc, val) => acc + val, 0);
        if (percentages.length > 0) {
            percentages[percentages.length - 1] += diffPercentages;
        }
        return percentages;
    }, [cashRevenue, integrationsRevenueByVendor]);
    const dataBackground = useMemo(() => integrationsRevenueByVendor.map((vendor) => vendorsColors(vendor.vendor)), [integrationsRevenueByVendor]);
    const dataLabels = useMemo(() => integrationsRevenueByVendor.map((vendor) => vendor.vendor.charAt(0).toUpperCase() + vendor.vendor.slice(1).toLowerCase()), [integrationsRevenueByVendor]);
    const data = useMemo(() => ({
        labels: dataLabels,
        datasets: [{
                data: percentagesCashRevenue,
                backgroundColor: dataBackground
            }]
    }), [dataLabels, percentagesCashRevenue, dataBackground]);
    const tooltipFormatter = useCallback((tooltipItem) => {
        const value = typeof tooltipItem.raw === 'number' ? tooltipItem.raw : 0;
        return `${value}%`;
    }, []);
    const dataLabelFormatter = useCallback((value) => {
        return `${Number(value) || 0} %`;
    }, []);
    const options = useMemo(() => ({
        responsive: true,
        cutout: '30%',
        offset: 8,
        elements: {
            arc: {
                borderWidth: 0,
                borderRadius: 8
            }
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: tooltipFormatter
                }
            },
            legend: {
                position: 'bottom',
                labels: {
                    boxWidth: 10,
                    boxHeight: 10,
                    useBorderRadius: true,
                    borderRadius: 2,
                    color: '#A7A7A7',
                    font: { size: 12 }
                }
            },
            datalabels: {
                color: '#fff',
                font: { weight: 'bold', size: 14 },
                formatter: dataLabelFormatter
            }
        }
    }), [tooltipFormatter, dataLabelFormatter]);
    return React.createElement(Doughnut, { data: data, options: options, plugins: [ChartDataLabels] });
};
export default DoughnutChart;
