import { message } from 'antd';
export const getEventName = (eventType, intl) => {
    var _a;
    return ((_a = {
        BUSY_WITH_PRIVATE_TRADER: intl.formatMessage({ id: 'common.privateTrader', defaultMessage: 'Один водій' }),
        BUSY_WITH_CREW: intl.formatMessage({ id: 'common.crew', defaultMessage: 'Экипаж' }),
        ROAD_ACCIDENT: intl.formatMessage({ id: 'common.roadAccident', defaultMessage: 'ДТП' }),
        ON_SERVICE_STATION: intl.formatMessage({ id: 'common.serviceStation', defaultMessage: 'СТО' }),
        VEHICLE_INSPECTION: intl.formatMessage({ id: 'common.ToService', defaultMessage: 'ТО-Сервис' }),
        AUTO_POUND: intl.formatMessage({ id: 'common.autoPound', defaultMessage: 'Штрафплощадка' }),
        OTHER: intl.formatMessage({ id: 'common.other', defaultMessage: 'Другое' }),
        RENTAL: intl.formatMessage({ id: 'common.rent', defaultMessage: 'Аренда' }),
        DAYOFF: intl.formatMessage({ id: 'common.dayOff', defaultMessage: 'Выходной' }),
        DRIVER_SICK_DAY: intl.formatMessage({ id: 'common.sickDay', defaultMessage: 'Больничный' })
    }[eventType]) !== null && _a !== void 0 ? _a : null);
};
export const getCashType = (type, intl) => {
    var _a;
    return ((_a = {
        CASH: intl.formatMessage({ id: 'common.cash', defaultMessage: 'Готівка' }),
        CASHLESS: intl.formatMessage({ id: 'common.cashLess', defaultMessage: 'Безготівковий' }),
        CORPORATIVE: intl.formatMessage({ id: 'common.corporative', defaultMessage: 'Корпоративний' }),
        CONTRACTOR: intl.formatMessage({ id: 'common.contractor', defaultMessage: 'Контрагент' })
    }[type]) !== null && _a !== void 0 ? _a : null);
};
export const getLocale = (language) => {
    switch (language) {
        case 'RU':
            return 'RU';
        case 'UK':
        case 'UA':
            return 'UK';
        case 'EN':
            return 'EN';
        case 'PL':
            return 'PL';
        case 'TG':
        case 'TJ':
            return 'TG';
        case 'UZ':
            return 'UZ';
        default:
            return 'EN';
    }
};
export const getCurrencySymbol = (code) => {
    switch (code) {
        case 'UAH':
            return '₴';
        case 'PLN':
            return 'zł';
        case 'USD':
            return '$';
        case 'EUR':
            return '€';
        case 'AED':
            return 'AED';
        case 'KZT':
            return '₸';
        case 'TRY':
            return '₺';
        default:
            return null;
    }
};
export const formatFileSize = (bytes) => {
    if (bytes === 0)
        return '0 B';
    const k = 1024;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
    const i = parseInt(String(Math.floor(Math.log(bytes) / Math.log(k))), 10);
    return `${Math.round(100 * (bytes / Math.pow(k, i))) / 100} ${sizes[i]}`;
};
export const getBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => { resolve(reader.result); };
    reader.onerror = (error) => { reject(error); };
});
export const formatNumberWithSuffix = (value) => {
    if (value < 1000) {
        return new Intl.NumberFormat('en-US').format(value);
    }
    const suffixes = ['', 'K', 'M', 'B', 'T'];
    const tier = Math.floor(Math.log10(value) / 3);
    const scaledValue = value / Math.pow(1000, tier);
    const formatted = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 1
    }).format(scaledValue);
    return `${formatted}${suffixes[tier]}`;
};
export const carTransferAcceptanceFieldSettings = [
    {
        field: 'COMMENT',
        demand: 'OPTIONAL'
    },
    {
        field: 'DEFECTS',
        demand: 'REQUIRED'
    },
    {
        field: 'ODOMETER',
        demand: 'REQUIRED'
    },
    {
        field: 'OIL_LEVEL',
        demand: 'REQUIRED'
    },
    {
        field: 'PHOTOS_TRUNC',
        demand: 'REQUIRED'
    },
    {
        field: 'PHOTOS_BODY_BACK',
        demand: 'REQUIRED'
    },
    {
        field: 'PHOTOS_BODY_LEFT',
        demand: 'REQUIRED'
    },
    {
        field: 'PHOTOS_DASHBOARD',
        demand: 'REQUIRED'
    },
    {
        field: 'PHOTOS_BODY_FRONT',
        demand: 'REQUIRED'
    },
    {
        field: 'PHOTOS_BODY_RIGHT',
        demand: 'REQUIRED'
    },
    {
        field: 'PHOTOS_CABIN_BACK',
        demand: 'REQUIRED'
    },
    {
        field: 'PHOTOS_UNDER_HOOD',
        demand: 'REQUIRED'
    },
    {
        field: 'PHOTOS_CABIN_FRONT',
        demand: 'REQUIRED'
    },
    {
        field: 'TECHNICAL_PASSPORT',
        demand: 'REQUIRED'
    },
    {
        field: 'COOLING_LIQUID_LEVEL',
        demand: 'REQUIRED'
    }
];
export const copyToClipboard = (intl, text) => {
    void navigator.clipboard.writeText(text);
    void message.success(intl.formatMessage({ id: 'common.copiedToClipboard', defaultMessage: 'Скопировано в буфер обмена' }), 3);
};
