import React, { useState, useRef, useCallback, useEffect } from 'react';
import classes from './HoldBtn.module.scss';
import globalClasses from '@/containers/App/Global.module.scss';
const HoldBtn = ({ holdEvent, loadingState, children }) => {
    const [isHolding, setIsHolding] = useState(false);
    const timerRef = useRef(null);
    useEffect(() => {
        return () => {
            setIsHolding(false);
            if (timerRef.current !== null) {
                clearTimeout(timerRef.current);
            }
        };
    }, []);
    const startHolding = useCallback(() => {
        setIsHolding(true);
        timerRef.current = window.setTimeout(() => {
            holdEvent();
        }, 2000);
    }, [holdEvent]);
    const stopHolding = useCallback(() => {
        setIsHolding(false);
        if (timerRef.current !== null) {
            clearTimeout(timerRef.current);
            timerRef.current = null;
        }
    }, []);
    const handleMouseDown = (e) => {
        e.preventDefault();
        startHolding();
    };
    const handleMouseUp = (e) => {
        e.preventDefault();
        stopHolding();
    };
    const handleMouseLeave = (e) => {
        e.preventDefault();
        stopHolding();
    };
    const handleTouchStart = () => {
        startHolding();
    };
    const handleTouchEnd = () => {
        stopHolding();
    };
    const handleTouchCancel = () => {
        stopHolding();
    };
    return (React.createElement("button", { className: `${globalClasses.bigBlueBtn} ${classes.pressHoldBtn} ${isHolding ? classes.onTouchStart : ''} ${loadingState ? globalClasses.loading : ''}`, onMouseDown: handleMouseDown, onMouseUp: handleMouseUp, onMouseLeave: handleMouseLeave, onTouchStart: handleTouchStart, onTouchEnd: handleTouchEnd, onTouchCancel: handleTouchCancel },
        React.createElement("span", null, children)));
};
export default HoldBtn;
