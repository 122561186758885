import { Tabs } from 'antd';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/isoWeek';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import Balance from './Balance/Balance';
import Debt from './Debt/Debt';
import Deposit from './Deposit/Deposit';
import classes from './Finances.module.scss';
import Income from './Income/Income';
import Breadcrumb from '@/atoms/Breadcrumb/Breadcrumb';
import globalClasses from '@/containers/App/Global.module.scss';
import useAppSelector from '@/hooks/useAppSelector';
import ArrowIcon from '@/icons/arrow-right.svg';
import { routes } from '@/utils/constants';
import { getActiveFinanceTab, setActiveFinanceTab } from '@/utils/localStorage';
dayjs.extend(weekOfYear);
const getItems = ({ intl, dateInfo, balanceDetails, debtDetails, depositDetails }) => {
    const items = [
        {
            key: 'income',
            label: intl.formatMessage({ id: 'common.income', defaultMessage: 'Дохід' }),
            children: React.createElement(Income, { dateInfo: dateInfo })
        }
    ];
    if (balanceDetails) {
        items.push({
            key: 'balance',
            label: intl.formatMessage({ id: 'common.balanceTitle', defaultMessage: 'Баланс' }),
            children: React.createElement(Balance, { dateInfo: dateInfo })
        });
    }
    if (debtDetails) {
        items.push({
            key: 'debt',
            label: intl.formatMessage({ id: 'common.debt', defaultMessage: 'Долг' }),
            children: React.createElement(Debt, { dateInfo: dateInfo })
        });
    }
    if (depositDetails) {
        items.push({
            key: 'deposit',
            label: intl.formatMessage({ id: 'common.deposit', defaultMessage: 'Депозит' }),
            children: React.createElement(Deposit, { dateInfo: dateInfo })
        });
    }
    return items;
};
const Finances = () => {
    const [dateInfo, setDateInfo] = useState(() => {
        const currentDate = dayjs();
        return {
            date: currentDate,
            week: currentDate.isoWeek(),
            year: currentDate.isoWeekYear()
        };
    });
    const { balanceDetails, debtDetails, depositDetails } = useAppSelector((state) => state.auth.settings);
    const activeTab = getActiveFinanceTab();
    const intl = useIntl();
    const items = getItems({ intl, dateInfo, balanceDetails, debtDetails, depositDetails });
    const changeWeek = (direction) => {
        const updatedDate = direction === 'next'
            ? dateInfo.date.add(1, 'week')
            : dateInfo.date.subtract(1, 'week');
        setDateInfo({
            date: updatedDate,
            week: updatedDate.isoWeek(),
            year: updatedDate.isoWeekYear()
        });
    };
    const handleTabClick = (key) => {
        setActiveFinanceTab(key);
    };
    const isNextWeekDisabled = dateInfo.week === dayjs().isoWeek() && dateInfo.year === dayjs().isoWeekYear();
    const weekRange = `${dateInfo.date.startOf('isoWeek').format('DD.MM.YYYY')} – ${dateInfo.date.endOf('isoWeek').format('DD.MM.YYYY')}`;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: globalClasses.topBox },
            React.createElement(Breadcrumb, { items: [{
                        id: 'home',
                        path: routes.cabinet.f,
                        text: intl.formatMessage({ id: 'common.toHome', defaultMessage: 'На головну' })
                    }] }),
            React.createElement("div", { className: globalClasses.title }, intl.formatMessage({ id: 'common.finances', defaultMessage: 'Финансы' })),
            React.createElement("div", { className: classes.calendarBtn },
                React.createElement("div", { onClick: () => { changeWeek('prev'); }, className: classes.calendarBtnArrow },
                    React.createElement(ArrowIcon, null)),
                React.createElement("div", { className: classes.calendarText },
                    `${dateInfo.week} ${intl.formatMessage({ id: 'common.week', defaultMessage: 'тиждень' })} ${dateInfo.year}`,
                    React.createElement("div", { className: classes.calendarWeekRange }, weekRange)),
                React.createElement("div", { onClick: () => { changeWeek('next'); }, className: `${classes.calendarBtnArrow} ${isNextWeekDisabled ? classes.disabled : ''}` },
                    React.createElement(ArrowIcon, null)))),
        React.createElement("div", { className: globalClasses.container },
            React.createElement(Tabs, { onTabClick: (key) => { handleTabClick(key); }, defaultActiveKey: activeTab !== null && activeTab !== void 0 ? activeTab : 'income', items: items }))));
};
export default Finances;
