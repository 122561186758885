import { message } from 'antd';
const apolloErrors = (code, intl) => {
    const errors = {
        USER_NOT_FOUND: intl.formatMessage({ id: 'error.invalidLoginPassword', defaultMessage: 'Введен неверный логин или пароль' }),
        BLOCKED: intl.formatMessage({ id: 'error.blocked', defaultMessage: 'Доступ в кабинет водителя ограничен, так как Ваш аккаунт находится в статусе "Уволен", "Черный список" или "Заблокирован"' }),
        ACCESS_DENIED: intl.formatMessage({ id: 'error.accessDenied', defaultMessage: 'У вас недостатньо прав' }),
        USER_ALREADY_ONBOARD: intl.formatMessage({ id: 'error.alreadyRegistered', defaultMessage: 'Ви вже зареєстровані' }),
        NO_ASSIGNED_CAR: intl.formatMessage({ id: 'error.noScheduledEvents', defaultMessage: 'У вас немає запланованих подій у графіку' }),
        FEATURE_DISABLED: intl.formatMessage({ id: 'common.tripCreationDisabled', defaultMessage: 'Функция создания поездок выключена' })
    };
    return errors[code] || intl.formatMessage({ id: 'error.error', defaultMessage: 'Произошла ошибка' });
};
const getErrorMessage = (err, intl) => {
    var _a, _b;
    const errorCode = (_b = (_a = err === null || err === void 0 ? void 0 : err.graphQLErrors[0]) === null || _a === void 0 ? void 0 : _a.extensions) === null || _b === void 0 ? void 0 : _b.code;
    return message.error(apolloErrors(errorCode, intl), 5);
};
export { getErrorMessage, apolloErrors };
