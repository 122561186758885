import { useQuery } from '@apollo/client';
import { Alert } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import Detailing from './Detailing/Detailing';
import StackedBar from './StackedBar/StackedBar';
import CollapsibleItem from '../CollapsibleItem/CollapsibleItem';
import classes from '../Finances.module.scss';
import { vendorsIcons } from '../helpers/helpers';
import Preloader from '@/atoms/Preloader/Preloader';
import ValueWithCurrency from '@/atoms/ValueWithCurrency/ValueWithCurrency';
import { GET_USER_INCOME } from '@/graphql/finances';
// const getItems = ({ integrationDailyStatements, dateInfo, currency }: { integrationDailyStatements: IntegrationDailyStatement[], dateInfo: IDateInfo, currency: Currency | null }): TabsProps['items'] => {
//   return [
//     {
//       key: 'stacked',
//       label: <StackedIcon />,
//       children: <StackedBar currency={currency} dateInfo={dateInfo} integrationDailyStatements={integrationDailyStatements} />
//     }
//   ]
// }
const Income = ({ dateInfo }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
    const intl = useIntl();
    const { loading, error, data } = useQuery(GET_USER_INCOME, {
        variables: {
            getUserIncomeInput: {
                week: dateInfo.week,
                year: dateInfo.year
            }
        },
        skip: !dateInfo.week || !dateInfo.year
    });
    if (loading)
        return React.createElement(Preloader, { inner: true });
    if (error)
        return React.createElement("div", { style: { marginTop: 20 } },
            React.createElement(Alert, { message: intl.formatMessage({ id: 'error.dataLoading', defaultMessage: 'Ошибка при загрузке данных' }), type: "error" }));
    const currency = (_c = (_b = (_a = data === null || data === void 0 ? void 0 : data.getUserIncome) === null || _a === void 0 ? void 0 : _a.income) === null || _b === void 0 ? void 0 : _b.currency) !== null && _c !== void 0 ? _c : null;
    const totalIncome = (_f = (_e = (_d = data === null || data === void 0 ? void 0 : data.getUserIncome) === null || _d === void 0 ? void 0 : _d.income) === null || _e === void 0 ? void 0 : _e.totalIncome) !== null && _f !== void 0 ? _f : null;
    const totalTrips = (_j = (_h = (_g = data === null || data === void 0 ? void 0 : data.getUserIncome) === null || _g === void 0 ? void 0 : _g.income) === null || _h === void 0 ? void 0 : _h.totalTrips) !== null && _j !== void 0 ? _j : null;
    const incomeWithoutSplitExpenses = (_m = (_l = (_k = data === null || data === void 0 ? void 0 : data.getUserIncome) === null || _k === void 0 ? void 0 : _k.income) === null || _l === void 0 ? void 0 : _l.incomeWithoutSplitExpenses) !== null && _m !== void 0 ? _m : null;
    const integrationDailyStatements = (_q = (_p = (_o = data === null || data === void 0 ? void 0 : data.getUserIncome) === null || _o === void 0 ? void 0 : _o.income) === null || _p === void 0 ? void 0 : _p.integrationDailyStatements) !== null && _q !== void 0 ? _q : [];
    const splitExpenses = (_u = (_t = (_s = (_r = data === null || data === void 0 ? void 0 : data.getUserIncome) === null || _r === void 0 ? void 0 : _r.income) === null || _s === void 0 ? void 0 : _s.splitExpenses) === null || _t === void 0 ? void 0 : _t.total) !== null && _u !== void 0 ? _u : null;
    const calculateVendorTotalIncome = integrationDailyStatements === null || integrationDailyStatements === void 0 ? void 0 : integrationDailyStatements.reduce((totals, { vendor, totalIncome }) => {
        totals[vendor] = (totals[vendor] || 0) + totalIncome;
        return totals;
    }, {});
    const calculateVendorTrips = integrationDailyStatements === null || integrationDailyStatements === void 0 ? void 0 : integrationDailyStatements.reduce((totals, { vendor, totalTrips }) => {
        totals[vendor] = (totals[vendor] || 0) + totalTrips;
        return totals;
    }, {});
    const calculateVendorTripsArray = Object.entries(calculateVendorTrips).map(([key, value]) => ({ vendor: key, value }));
    // const items = getItems({ integrationDailyStatements, dateInfo, currency })
    return (React.createElement("div", { className: classes.income },
        React.createElement("div", { className: classes.box },
            React.createElement("div", { className: classes.boxTitle }, intl.formatMessage({ id: 'common.totalIncome', defaultMessage: 'Загальний дохід' })),
            React.createElement("div", { className: classes.value },
                React.createElement(ValueWithCurrency, { currencySymbol: currency, value: totalIncome, separationValue: true, minimumFractionDigits: true, withZero: true })),
            !(totalIncome === incomeWithoutSplitExpenses) && incomeWithoutSplitExpenses != null && incomeWithoutSplitExpenses !== 0 && (React.createElement("div", { className: classes.subValue },
                intl.formatMessage({ id: 'common.incomeWithoutSplitExpenses', defaultMessage: 'Дохід з вирахуванням витрат становить' }),
                React.createElement(ValueWithCurrency, { currencySymbol: currency, value: incomeWithoutSplitExpenses, separationValue: true, minimumFractionDigits: true, withZero: true }))),
            React.createElement(StackedBar, { currency: currency, dateInfo: dateInfo, integrationDailyStatements: integrationDailyStatements }),
            integrationDailyStatements && (integrationDailyStatements === null || integrationDailyStatements === void 0 ? void 0 : integrationDailyStatements.length) !== 0 && (React.createElement(Detailing, { currency: currency, calculateVendorTotalIncome: calculateVendorTotalIncome }))),
        splitExpenses != null && splitExpenses !== 0 && (React.createElement(CollapsibleItem, { currency: currency, totalCurrency: splitExpenses, title: intl.formatMessage({ id: 'common.splitExpenses', defaultMessage: 'Загальні витрати' }) })),
        totalTrips != null && totalTrips !== 0 && (React.createElement(CollapsibleItem, { totalValue: totalTrips, title: intl.formatMessage({ id: 'common.numberOfTrips', defaultMessage: 'Кількість поїздок' }) }, calculateVendorTripsArray === null || calculateVendorTripsArray === void 0 ? void 0 : calculateVendorTripsArray.map((item) => (React.createElement("div", { key: uuidv4(), className: classes.detailingRow },
            React.createElement("div", { className: classes.detailingRowCell },
                React.createElement("div", { className: classes.detailingRowCellName },
                    vendorsIcons(item.vendor),
                    React.createElement("span", null, item.vendor))),
            React.createElement("div", { className: classes.detailingRowCell },
                React.createElement("div", { className: classes.detailingRowCellValue }, item.value)))))))));
};
export default Income;
