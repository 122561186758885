import { useQuery } from '@apollo/client';
import { Alert } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import CollapsibleItem from '../CollapsibleItem/CollapsibleItem';
import classes from '../Finances.module.scss';
import Preloader from '@/atoms/Preloader/Preloader';
import ValueWithCurrency from '@/atoms/ValueWithCurrency/ValueWithCurrency';
import { GET_USER_DEBT } from '@/graphql/finances';
const Debt = ({ dateInfo }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
    const intl = useIntl();
    const { loading, error, data } = useQuery(GET_USER_DEBT, {
        variables: {
            getUserDebtInput: {
                week: dateInfo.week,
                year: dateInfo.year
            }
        },
        skip: !dateInfo.week || !dateInfo.year
    });
    if (loading)
        return React.createElement(Preloader, { inner: true });
    if (error)
        return React.createElement("div", { style: { marginTop: 20 } },
            React.createElement(Alert, { message: intl.formatMessage({ id: 'error.dataLoading', defaultMessage: 'Ошибка при загрузке данных' }), type: "error" }));
    const currency = (_c = (_b = (_a = data === null || data === void 0 ? void 0 : data.getUserDebt) === null || _a === void 0 ? void 0 : _a.debt) === null || _b === void 0 ? void 0 : _b.currency) !== null && _c !== void 0 ? _c : null;
    const totalDebt = (_g = (_f = (_e = (_d = data === null || data === void 0 ? void 0 : data.getUserDebt) === null || _d === void 0 ? void 0 : _d.debt) === null || _e === void 0 ? void 0 : _e.debt) === null || _f === void 0 ? void 0 : _f.total) !== null && _g !== void 0 ? _g : null;
    const lastWeekTotalDebt = (_l = (_k = (_j = (_h = data === null || data === void 0 ? void 0 : data.getUserDebt) === null || _h === void 0 ? void 0 : _h.debt) === null || _j === void 0 ? void 0 : _j.debt) === null || _k === void 0 ? void 0 : _k.lastWeekTotalDebt) !== null && _l !== void 0 ? _l : null;
    const topUpDebt = (_q = (_p = (_o = (_m = data === null || data === void 0 ? void 0 : data.getUserDebt) === null || _m === void 0 ? void 0 : _m.debt) === null || _o === void 0 ? void 0 : _o.debt) === null || _p === void 0 ? void 0 : _p.topUpDebt) !== null && _q !== void 0 ? _q : null;
    const totalPayOff = (_u = (_t = (_s = (_r = data === null || data === void 0 ? void 0 : data.getUserDebt) === null || _r === void 0 ? void 0 : _r.debt) === null || _s === void 0 ? void 0 : _s.debt) === null || _t === void 0 ? void 0 : _t.totalPayOff) !== null && _u !== void 0 ? _u : null;
    return (React.createElement("div", { className: classes.income },
        React.createElement("div", { className: classes.box },
            React.createElement("div", { className: classes.boxTitle }, intl.formatMessage({ id: 'common.totalDebt', defaultMessage: 'Загальний борг' })),
            React.createElement("div", { className: classes.value },
                React.createElement(ValueWithCurrency, { currencySymbol: currency, value: totalDebt, separationValue: true, minimumFractionDigits: true, withZero: true }))),
        lastWeekTotalDebt != null && lastWeekTotalDebt !== 0 && (React.createElement(CollapsibleItem, { currency: currency, totalCurrency: lastWeekTotalDebt, title: intl.formatMessage({ id: 'common.balanceFromLastWeek', defaultMessage: 'Остаток с прошлой недели' }) })),
        topUpDebt != null && topUpDebt !== 0 && (React.createElement(CollapsibleItem, { currency: currency, totalCurrency: topUpDebt, title: intl.formatMessage({ id: 'common.debtAccrual', defaultMessage: 'Начисление долга' }) })),
        totalPayOff != null && totalPayOff !== 0 && (React.createElement(CollapsibleItem, { currency: currency, totalCurrency: totalPayOff, title: intl.formatMessage({ id: 'common.debtRepayment', defaultMessage: 'Погашение долга' }) }))));
};
export default Debt;
