import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip, Legend } from 'chart.js';
import dayjs from 'dayjs';
import React from 'react';
import { Bar } from 'react-chartjs-2';
import classes from '../../Finances.module.scss';
import { vendorsColors } from '../../helpers/helpers';
import { NUM_LOCALE } from '@/utils/constants';
import { formatNumberWithSuffix, getCurrencySymbol } from '@/utils/helpers';
ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);
const StackedBar = ({ integrationDailyStatements, dateInfo, currency }) => {
    const weekDates = Array.from({ length: 7 }, (_, i) => dayjs(dateInfo === null || dateInfo === void 0 ? void 0 : dateInfo.date).startOf('isoWeek').add(i, 'day').format('YYYY-MM-DD'));
    const weekDatesLabels = weekDates === null || weekDates === void 0 ? void 0 : weekDates.map((day) => dayjs(day).format('DD/MM'));
    const dailyIntegrationByVendor = integrationDailyStatements === null || integrationDailyStatements === void 0 ? void 0 : integrationDailyStatements.reduce((vendors, { vendor, totalIncome, date }) => {
        if (!vendors[vendor]) {
            vendors[vendor] = [];
        }
        vendors[vendor].push({ date, totalIncome });
        return vendors;
    }, {});
    const datasets = Object
        .entries(dailyIntegrationByVendor)
        .map(([key, value]) => ({ vendor: key, value }))
        .map((vendor) => {
        var _a, _b;
        const dataDays = weekDates === null || weekDates === void 0 ? void 0 : weekDates.map((day) => {
            var _a, _b;
            const getDay = (_a = vendor === null || vendor === void 0 ? void 0 : vendor.value) === null || _a === void 0 ? void 0 : _a.find((item) => item.date === day);
            return (_b = getDay === null || getDay === void 0 ? void 0 : getDay.totalIncome) !== null && _b !== void 0 ? _b : 0;
        });
        return {
            label: ((_a = vendor === null || vendor === void 0 ? void 0 : vendor.vendor) === null || _a === void 0 ? void 0 : _a.charAt(0).toUpperCase()) + ((_b = vendor === null || vendor === void 0 ? void 0 : vendor.vendor) === null || _b === void 0 ? void 0 : _b.slice(1).toLowerCase()),
            data: dataDays,
            backgroundColor: vendorsColors(vendor === null || vendor === void 0 ? void 0 : vendor.vendor)
        };
    });
    const data = {
        labels: weekDatesLabels,
        datasets
    };
    const options = {
        plugins: {
            tooltip: {
                mode: 'index',
                intersect: false,
                callbacks: {
                    label: function (context) {
                        var _a, _b;
                        let label = (_a = context.dataset.label) !== null && _a !== void 0 ? _a : '';
                        const value = `${currency ? getCurrencySymbol(currency !== null && currency !== void 0 ? currency : '') : ''} ${(_b = context.parsed.y) === null || _b === void 0 ? void 0 : _b.toLocaleString(NUM_LOCALE, { minimumFractionDigits: 2 })}`;
                        if (context.parsed.y !== null) {
                            label = `${label} ${value}`;
                        }
                        return label;
                    }
                }
            },
            legend: {
                position: 'bottom',
                labels: {
                    boxWidth: 10,
                    boxHeight: 10,
                    useBorderRadius: true,
                    borderRadius: 2,
                    color: '#A7A7A7',
                    font: {
                        size: 12
                    }
                }
            }
        },
        elements: {
            bar: {
                borderRadius: 2
            }
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                stacked: true,
                grid: {
                    color: '#fff'
                },
                border: {
                    color: '#F6F6F6'
                },
                ticks: {
                    color: '#474747',
                    font: {
                        size: 10,
                        weight: 600,
                        family: 'Inter'
                    }
                }
            },
            y: {
                stacked: true,
                beginAtZero: true,
                border: {
                    color: '#fff'
                },
                grid: {
                    tickColor: '#fff',
                    color: '#F6F6F6'
                },
                ticks: {
                    color: '#474747',
                    font: {
                        size: 10,
                        weight: 600,
                        family: 'Inter'
                    },
                    callback: (value) => {
                        return typeof value === 'number' ? `${currency ? getCurrencySymbol(currency !== null && currency !== void 0 ? currency : '') : ''} ${formatNumberWithSuffix(value)}` : '';
                    }
                }
            }
        }
    };
    return (React.createElement("div", { className: classes.diagramHolder },
        React.createElement(Bar, { options: options, data: data, height: 210 })));
};
export default StackedBar;
