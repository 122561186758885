import { useQuery } from '@apollo/client';
import { Alert } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import DoughnutChart from './DoughnutChart/DoughnutChart';
import CollapsibleItem from '../CollapsibleItem/CollapsibleItem';
import DetailingRow from '../DetailingRow/DetailingRow';
import classes from '../Finances.module.scss';
import { vendorsIcons } from '../helpers/helpers';
import Preloader from '@/atoms/Preloader/Preloader';
import ValueWithCurrency from '@/atoms/ValueWithCurrency/ValueWithCurrency';
import { GET_USER_REVISE } from '@/graphql/finances';
import TimeIcon from '@/icons/time.svg';
import { getCurrencySymbol } from '@/utils/helpers';
const Balance = ({ dateInfo }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31, _32, _33, _34, _35, _36, _37, _38, _39, _40, _41, _42, _43, _44, _45, _46, _47, _48, _49, _50, _51, _52, _53, _54, _55, _56, _57, _58, _59, _60, _61, _62, _63, _64, _65, _66, _67, _68;
    const intl = useIntl();
    const { loading, error, data } = useQuery(GET_USER_REVISE, {
        variables: {
            getUserReviseInput: {
                week: dateInfo.week,
                year: dateInfo.year
            }
        },
        skip: !dateInfo.week || !dateInfo.year
    });
    if (loading)
        return React.createElement(Preloader, { inner: true });
    if (error)
        return React.createElement("div", { style: { marginTop: 20 } },
            React.createElement(Alert, { message: intl.formatMessage({ id: 'error.dataLoading', defaultMessage: 'Ошибка при загрузке данных' }), type: "error" }));
    const currency = (_b = (_a = data === null || data === void 0 ? void 0 : data.getUserRevise) === null || _a === void 0 ? void 0 : _a.revise) === null || _b === void 0 ? void 0 : _b.currency;
    const totalRevenue = (_f = (_e = (_d = (_c = data === null || data === void 0 ? void 0 : data.getUserRevise) === null || _c === void 0 ? void 0 : _c.revise) === null || _d === void 0 ? void 0 : _d.revenueByTariffRules) === null || _e === void 0 ? void 0 : _e.totalRevenue) !== null && _f !== void 0 ? _f : null;
    const driverPayableSumsWithTariffRules = (_k = (_j = (_h = (_g = data === null || data === void 0 ? void 0 : data.getUserRevise) === null || _g === void 0 ? void 0 : _g.revise) === null || _h === void 0 ? void 0 : _h.revenueByTariffRules) === null || _j === void 0 ? void 0 : _j.driverPayableSumsWithTariffRules) !== null && _k !== void 0 ? _k : [];
    const tariffTax = (_p = (_o = (_m = (_l = data === null || data === void 0 ? void 0 : data.getUserRevise) === null || _l === void 0 ? void 0 : _l.revise) === null || _m === void 0 ? void 0 : _m.revenueByTariffRules) === null || _o === void 0 ? void 0 : _o.tariffTax) !== null && _p !== void 0 ? _p : null;
    const accounting = (_t = (_s = (_r = (_q = data === null || data === void 0 ? void 0 : data.getUserRevise) === null || _q === void 0 ? void 0 : _q.revise) === null || _r === void 0 ? void 0 : _r.revenueByTariffRules) === null || _s === void 0 ? void 0 : _s.accounting) !== null && _t !== void 0 ? _t : null;
    const totalBonuses = (_x = (_w = (_v = (_u = data === null || data === void 0 ? void 0 : data.getUserRevise) === null || _u === void 0 ? void 0 : _u.revise) === null || _v === void 0 ? void 0 : _v.revenueByTariffRules) === null || _w === void 0 ? void 0 : _w.totalBonuses) !== null && _x !== void 0 ? _x : null;
    const rewards = (_1 = (_0 = (_z = (_y = data === null || data === void 0 ? void 0 : data.getUserRevise) === null || _y === void 0 ? void 0 : _y.revise) === null || _z === void 0 ? void 0 : _z.integrationsRevenue) === null || _0 === void 0 ? void 0 : _0.rewards) !== null && _1 !== void 0 ? _1 : null;
    const totalRefunds = (_5 = (_4 = (_3 = (_2 = data === null || data === void 0 ? void 0 : data.getUserRevise) === null || _2 === void 0 ? void 0 : _2.revise) === null || _3 === void 0 ? void 0 : _3.integrationsRevenue) === null || _4 === void 0 ? void 0 : _4.totalRefunds) !== null && _5 !== void 0 ? _5 : null;
    const tips = (_9 = (_8 = (_7 = (_6 = data === null || data === void 0 ? void 0 : data.getUserRevise) === null || _6 === void 0 ? void 0 : _6.revise) === null || _7 === void 0 ? void 0 : _7.integrationsRevenue) === null || _8 === void 0 ? void 0 : _8.tips) !== null && _9 !== void 0 ? _9 : null;
    const driverBonuses = (_14 = (_13 = (_12 = (_11 = (_10 = data === null || data === void 0 ? void 0 : data.getUserRevise) === null || _10 === void 0 ? void 0 : _10.revise) === null || _11 === void 0 ? void 0 : _11.revenueByTariffRules) === null || _12 === void 0 ? void 0 : _12.fleetBonuses) === null || _13 === void 0 ? void 0 : _13.driverBonuses) !== null && _14 !== void 0 ? _14 : null;
    const cashRevenue = (_18 = (_17 = (_16 = (_15 = data === null || data === void 0 ? void 0 : data.getUserRevise) === null || _15 === void 0 ? void 0 : _15.revise) === null || _16 === void 0 ? void 0 : _16.integrationsRevenue) === null || _17 === void 0 ? void 0 : _17.cashRevenue) !== null && _18 !== void 0 ? _18 : null;
    const nonZeroIntegrationsRevenueByVendor = (_23 = (_22 = (_21 = (_20 = (_19 = data === null || data === void 0 ? void 0 : data.getUserRevise) === null || _19 === void 0 ? void 0 : _19.revise) === null || _20 === void 0 ? void 0 : _20.integrationsRevenue) === null || _21 === void 0 ? void 0 : _21.byVendor) === null || _22 === void 0 ? void 0 : _22.filter((vendor) => vendor === null || vendor === void 0 ? void 0 : vendor.cashRevenue).sort((a, b) => b.cashRevenue - a.cashRevenue)) !== null && _23 !== void 0 ? _23 : [];
    const totalTollRoads = (_27 = (_26 = (_25 = (_24 = data === null || data === void 0 ? void 0 : data.getUserRevise) === null || _24 === void 0 ? void 0 : _24.revise) === null || _25 === void 0 ? void 0 : _25.driverRevenue) === null || _26 === void 0 ? void 0 : _26.totalTollRoads) !== null && _27 !== void 0 ? _27 : null;
    const tollRoads = (_31 = (_30 = (_29 = (_28 = data === null || data === void 0 ? void 0 : data.getUserRevise) === null || _28 === void 0 ? void 0 : _28.revise) === null || _29 === void 0 ? void 0 : _29.driverRevenue) === null || _30 === void 0 ? void 0 : _30.tollRoads) !== null && _31 !== void 0 ? _31 : null;
    const tollRoadsPayments = (_35 = (_34 = (_33 = (_32 = data === null || data === void 0 ? void 0 : data.getUserRevise) === null || _32 === void 0 ? void 0 : _32.revise) === null || _33 === void 0 ? void 0 : _33.driverRevenue) === null || _34 === void 0 ? void 0 : _34.tollRoadsPayments) !== null && _35 !== void 0 ? _35 : null;
    const lastWeekBalance = (_39 = (_38 = (_37 = (_36 = data === null || data === void 0 ? void 0 : data.getUserRevise) === null || _36 === void 0 ? void 0 : _36.revise) === null || _37 === void 0 ? void 0 : _37.driverRevenue) === null || _38 === void 0 ? void 0 : _38.lastWeekBalance) !== null && _39 !== void 0 ? _39 : null;
    const driverRevenueTotal = (_43 = (_42 = (_41 = (_40 = data === null || data === void 0 ? void 0 : data.getUserRevise) === null || _40 === void 0 ? void 0 : _40.revise) === null || _41 === void 0 ? void 0 : _41.driverRevenue) === null || _42 === void 0 ? void 0 : _42.total) !== null && _43 !== void 0 ? _43 : null;
    const totalCompensation = (_47 = (_46 = (_45 = (_44 = data === null || data === void 0 ? void 0 : data.getUserRevise) === null || _44 === void 0 ? void 0 : _44.revise) === null || _45 === void 0 ? void 0 : _45.driverRevenue) === null || _46 === void 0 ? void 0 : _46.totalCompensation) !== null && _47 !== void 0 ? _47 : null;
    const compensation = (_51 = (_50 = (_49 = (_48 = data === null || data === void 0 ? void 0 : data.getUserRevise) === null || _48 === void 0 ? void 0 : _48.revise) === null || _49 === void 0 ? void 0 : _49.driverRevenue) === null || _50 === void 0 ? void 0 : _50.compensation) !== null && _51 !== void 0 ? _51 : null;
    const driverRevenue = (_53 = (_52 = data === null || data === void 0 ? void 0 : data.getUserRevise) === null || _52 === void 0 ? void 0 : _52.revise) === null || _53 === void 0 ? void 0 : _53.driverRevenue;
    const rent = (_55 = (_54 = data === null || data === void 0 ? void 0 : data.getUserRevise) === null || _54 === void 0 ? void 0 : _54.revise) === null || _55 === void 0 ? void 0 : _55.rent;
    const fines = (_57 = (_56 = data === null || data === void 0 ? void 0 : data.getUserRevise) === null || _56 === void 0 ? void 0 : _56.revise) === null || _57 === void 0 ? void 0 : _57.fines;
    const salary = (_59 = (_58 = data === null || data === void 0 ? void 0 : data.getUserRevise) === null || _58 === void 0 ? void 0 : _58.revise) === null || _59 === void 0 ? void 0 : _59.salary;
    const driverPayments = (_61 = (_60 = data === null || data === void 0 ? void 0 : data.getUserRevise) === null || _60 === void 0 ? void 0 : _60.revise) === null || _61 === void 0 ? void 0 : _61.driverPayments;
    const cashBalance = (_63 = (_62 = data === null || data === void 0 ? void 0 : data.getUserRevise) === null || _62 === void 0 ? void 0 : _62.revise) === null || _63 === void 0 ? void 0 : _63.cashBalance;
    const splitExpensesDriver = (_66 = (_65 = (_64 = data === null || data === void 0 ? void 0 : data.getUserRevise) === null || _64 === void 0 ? void 0 : _64.revise) === null || _65 === void 0 ? void 0 : _65.splitExpenses) === null || _66 === void 0 ? void 0 : _66.driver;
    const tariff = (_68 = (_67 = data === null || data === void 0 ? void 0 : data.getUserRevise) === null || _67 === void 0 ? void 0 : _67.revise) === null || _68 === void 0 ? void 0 : _68.tariff;
    return (React.createElement("div", { className: classes.balance },
        totalRevenue != null && totalRevenue !== 0 && (React.createElement(CollapsibleItem, { totalCurrency: totalRevenue, currency: currency, title: intl.formatMessage({ id: 'common.driverIncome', defaultMessage: 'Прибуток водія' }) },
            (driverPayableSumsWithTariffRules === null || driverPayableSumsWithTariffRules === void 0 ? void 0 : driverPayableSumsWithTariffRules.length)
                ? (React.createElement("div", { className: classes.table },
                    React.createElement("div", { className: classes.tableHead },
                        React.createElement("div", { className: classes.tableCell }, intl.formatMessage({ id: 'common.typeOfRate', defaultMessage: 'Тип ставки' })),
                        React.createElement("div", { className: classes.tableCell }, intl.formatMessage({ id: 'common.income', defaultMessage: 'Дохід' }))),
                    React.createElement("div", { className: classes.tableBody }, driverPayableSumsWithTariffRules.map((rule) => (React.createElement("div", { key: uuidv4(), className: classes.tableRow },
                        React.createElement("div", { className: classes.tableCell },
                            rule.tariffRule.from,
                            rule.tariffRule.to >= 1000000 ? '+' : `-${rule.tariffRule.to}`,
                            (tariff === null || tariff === void 0 ? void 0 : tariff.targetMarker) === 'TRIPS'
                                ? ` ${intl.formatMessage({ id: 'common.trips', defaultMessage: 'поїздок' })}, `
                                : (tariff === null || tariff === void 0 ? void 0 : tariff.targetMarker) === 'TOTAL_INCOME' ? ` ${getCurrencySymbol(currency !== null && currency !== void 0 ? currency : '')}, ` : null,
                            rule.tariffRule.rate,
                            "%"),
                        React.createElement("div", { className: classes.tableCell },
                            React.createElement(ValueWithCurrency, { currencySymbol: currency, value: rule.driverPayableSumByTariff, separationValue: true, minimumFractionDigits: true, withZero: true }))))))))
                : null,
            tariffTax != null && tariffTax !== 0 && (React.createElement(DetailingRow, { cellNameContent: intl.formatMessage({ id: 'common.tax', defaultMessage: 'Податок' }), cellValueContent: React.createElement(ValueWithCurrency, { currencySymbol: currency, value: tariffTax, separationValue: true, minimumFractionDigits: true, withZero: true }) })),
            accounting != null && accounting !== 0 && (React.createElement(DetailingRow, { cellNameContent: intl.formatMessage({ id: 'common.accounting', defaultMessage: 'Бухгалтерія' }), cellValueContent: React.createElement(ValueWithCurrency, { currencySymbol: currency, value: accounting, separationValue: true, minimumFractionDigits: true, withZero: true }) })),
            totalBonuses != null && totalBonuses !== 0 && (React.createElement(DetailingRow, { cellNameContent: intl.formatMessage({ id: 'common.fleetBonus', defaultMessage: 'Бонус від автопарку' }), cellValueContent: React.createElement(ValueWithCurrency, { currencySymbol: currency, value: totalBonuses, separationValue: true, minimumFractionDigits: true, withZero: true }) })),
            driverBonuses != null && driverBonuses !== 0 && (React.createElement(DetailingRow, { cellNameContent: intl.formatMessage({ id: 'common.bonusesForTrips', defaultMessage: 'Бонуси за поїздки' }), cellValueContent: React.createElement(ValueWithCurrency, { currencySymbol: currency, value: driverBonuses, separationValue: true, minimumFractionDigits: true, withZero: true }) })))),
        cashRevenue != null && cashRevenue !== 0 && (React.createElement(CollapsibleItem, { title: intl.formatMessage({ id: 'common.cashFromClients', defaultMessage: 'Готівка від клієнтів' }), currency: currency, totalCurrency: cashRevenue }, (nonZeroIntegrationsRevenueByVendor === null || nonZeroIntegrationsRevenueByVendor === void 0 ? void 0 : nonZeroIntegrationsRevenueByVendor.length) && cashRevenue
            ? (React.createElement(React.Fragment, null,
                React.createElement(DoughnutChart, { cashRevenue: cashRevenue, integrationsRevenueByVendor: nonZeroIntegrationsRevenueByVendor }),
                React.createElement("div", { className: classes.detailingBody }, nonZeroIntegrationsRevenueByVendor === null || nonZeroIntegrationsRevenueByVendor === void 0 ? void 0 : nonZeroIntegrationsRevenueByVendor.map((vendor) => (React.createElement("div", { key: uuidv4(), className: classes.detailingRow },
                    React.createElement("div", { className: classes.detailingRowCell },
                        React.createElement("div", { className: classes.detailingRowCellName },
                            vendorsIcons(vendor.vendor),
                            React.createElement("span", null, vendor.vendor))),
                    React.createElement("div", { className: classes.detailingRowCell },
                        React.createElement("div", { className: classes.detailingRowCellValue },
                            React.createElement(ValueWithCurrency, { currencySymbol: currency, value: vendor.cashRevenue, separationValue: true, minimumFractionDigits: true, withZero: true })))))))))
            : null)),
        totalRefunds != null && totalRefunds !== 0 && (React.createElement(CollapsibleItem, { title: intl.formatMessage({ id: 'common.refund', defaultMessage: 'Возврат средств' }), currency: currency, totalCurrency: totalRefunds },
            tips != null && tips !== 0 && (React.createElement(DetailingRow, { cellNameContent: intl.formatMessage({ id: 'common.tips', defaultMessage: 'Чаевые' }), cellValueContent: React.createElement(ValueWithCurrency, { currencySymbol: currency, value: tips, separationValue: true, minimumFractionDigits: true, withZero: true }) })),
            rewards != null && rewards !== 0 && (React.createElement(DetailingRow, { cellNameContent: intl.formatMessage({ id: 'common.driverReward', defaultMessage: 'Награда водителя' }), cellValueContent: React.createElement(ValueWithCurrency, { currencySymbol: currency, value: rewards, separationValue: true, minimumFractionDigits: true, withZero: true }) })))),
        (driverRevenue === null || driverRevenue === void 0 ? void 0 : driverRevenue.totalTransfers) != null && (driverRevenue === null || driverRevenue === void 0 ? void 0 : driverRevenue.totalTransfers) !== 0 && (React.createElement(CollapsibleItem, { title: intl.formatMessage({ id: 'common.transfers', defaultMessage: 'Трансфери' }), currency: currency, totalCurrency: driverRevenue === null || driverRevenue === void 0 ? void 0 : driverRevenue.totalTransfers },
            (driverRevenue === null || driverRevenue === void 0 ? void 0 : driverRevenue.topUpDeposit) != null && (driverRevenue === null || driverRevenue === void 0 ? void 0 : driverRevenue.topUpDeposit) !== 0 && (React.createElement(DetailingRow, { cellNameContent: intl.formatMessage({ id: 'common.depositReplenishment', defaultMessage: 'Поповнення депозиту' }), cellValueContent: React.createElement(ValueWithCurrency, { currencySymbol: currency, value: driverRevenue === null || driverRevenue === void 0 ? void 0 : driverRevenue.topUpDeposit, separationValue: true, minimumFractionDigits: true, withZero: true }) })),
            (driverRevenue === null || driverRevenue === void 0 ? void 0 : driverRevenue.deductFromDeposit) != null && (driverRevenue === null || driverRevenue === void 0 ? void 0 : driverRevenue.deductFromDeposit) !== 0 && (React.createElement(DetailingRow, { cellNameContent: intl.formatMessage({ id: 'common.depositTransfer', defaultMessage: 'Переказ з депозиту' }), cellValueContent: React.createElement(ValueWithCurrency, { currencySymbol: currency, value: driverRevenue === null || driverRevenue === void 0 ? void 0 : driverRevenue.deductFromDeposit, separationValue: true, minimumFractionDigits: true, withZero: true }) })),
            (driverRevenue === null || driverRevenue === void 0 ? void 0 : driverRevenue.payOffDebt) != null && (driverRevenue === null || driverRevenue === void 0 ? void 0 : driverRevenue.payOffDebt) !== 0 && (React.createElement(DetailingRow, { cellNameContent: intl.formatMessage({ id: 'common.debtRepayment', defaultMessage: 'Погашення боргу' }), cellValueContent: React.createElement(ValueWithCurrency, { currencySymbol: currency, value: driverRevenue === null || driverRevenue === void 0 ? void 0 : driverRevenue.payOffDebt, separationValue: true, minimumFractionDigits: true, withZero: true }) })))),
        (rent === null || rent === void 0 ? void 0 : rent.total) != null && (rent === null || rent === void 0 ? void 0 : rent.total) !== 0 && (React.createElement(CollapsibleItem, { title: intl.formatMessage({ id: 'common.rent', defaultMessage: 'Оренда' }), currency: currency, totalCurrency: rent === null || rent === void 0 ? void 0 : rent.total },
            (rent === null || rent === void 0 ? void 0 : rent.carOverrunRentalPayment) != null && (rent === null || rent === void 0 ? void 0 : rent.carOverrunRentalPayment) !== 0 && (React.createElement(DetailingRow, { cellNameContent: intl.formatMessage({ id: 'common.paymentPerKilometer', defaultMessage: 'Оплата за кілометраж' }), cellValueContent: React.createElement(ValueWithCurrency, { currencySymbol: currency, value: rent === null || rent === void 0 ? void 0 : rent.carOverrunRentalPayment, separationValue: true, minimumFractionDigits: true, withZero: true }) })),
            (rent === null || rent === void 0 ? void 0 : rent.rentDebt) != null && (rent === null || rent === void 0 ? void 0 : rent.rentDebt) !== 0 && (React.createElement(DetailingRow, { cellNameContent: intl.formatMessage({ id: 'common.payments', defaultMessage: 'Платежі' }), cellValueContent: React.createElement(ValueWithCurrency, { currencySymbol: currency, value: rent === null || rent === void 0 ? void 0 : rent.rentDebt, separationValue: true, minimumFractionDigits: true, withZero: true }) })))),
        (fines === null || fines === void 0 ? void 0 : fines.total) != null && (fines === null || fines === void 0 ? void 0 : fines.total) !== 0 && (React.createElement(CollapsibleItem, { title: intl.formatMessage({ id: 'common.fines', defaultMessage: 'Штрафи' }), currency: currency, totalCurrency: fines === null || fines === void 0 ? void 0 : fines.total },
            (fines === null || fines === void 0 ? void 0 : fines.overrun) != null && (fines === null || fines === void 0 ? void 0 : fines.overrun) !== 0 && (React.createElement(DetailingRow, { cellNameContent: intl.formatMessage({ id: 'common.overMileage', defaultMessage: 'Перепробіг' }), cellValueContent: React.createElement(ValueWithCurrency, { currencySymbol: currency, value: fines === null || fines === void 0 ? void 0 : fines.overrun, separationValue: true, minimumFractionDigits: true, withZero: true }) })),
            (fines === null || fines === void 0 ? void 0 : fines.overspeed) != null && (fines === null || fines === void 0 ? void 0 : fines.overspeed) !== 0 && (React.createElement(DetailingRow, { cellNameContent: intl.formatMessage({ id: 'common.speedingFleetTracker', defaultMessage: 'Перевищення швидкості (трекинг автопарку)' }), cellValueContent: React.createElement(ValueWithCurrency, { currencySymbol: currency, value: fines === null || fines === void 0 ? void 0 : fines.overspeed, separationValue: true, minimumFractionDigits: true, withZero: true }) })),
            (fines === null || fines === void 0 ? void 0 : fines.trafficRules) != null && (fines === null || fines === void 0 ? void 0 : fines.trafficRules) !== 0 && (React.createElement(DetailingRow, { cellNameContent: intl.formatMessage({ id: 'common.trafficRules', defaultMessage: 'ПДР' }), cellValueContent: React.createElement(ValueWithCurrency, { currencySymbol: currency, value: fines === null || fines === void 0 ? void 0 : fines.trafficRules, separationValue: true, minimumFractionDigits: true, withZero: true }) })),
            (fines === null || fines === void 0 ? void 0 : fines.downtime) != null && (fines === null || fines === void 0 ? void 0 : fines.downtime) !== 0 && (React.createElement(DetailingRow, { cellNameContent: intl.formatMessage({ id: 'common.carStopped', defaultMessage: 'Простій авто' }), cellValueContent: React.createElement(ValueWithCurrency, { currencySymbol: currency, value: fines === null || fines === void 0 ? void 0 : fines.downtime, separationValue: true, minimumFractionDigits: true, withZero: true }) })),
            (fines === null || fines === void 0 ? void 0 : fines.firedOutDeduct) != null && (fines === null || fines === void 0 ? void 0 : fines.firedOutDeduct) !== 0 && (React.createElement(DetailingRow, { cellNameContent: intl.formatMessage({ id: 'common.balanceWriteOffAfterDismissal', defaultMessage: 'Списання балансу після звільнення' }), cellValueContent: React.createElement(ValueWithCurrency, { currencySymbol: currency, value: fines === null || fines === void 0 ? void 0 : fines.firedOutDeduct, separationValue: true, minimumFractionDigits: true, withZero: true }) })),
            (fines === null || fines === void 0 ? void 0 : fines.lowEfficiency) != null && (fines === null || fines === void 0 ? void 0 : fines.lowEfficiency) !== 0 && (React.createElement(DetailingRow, { cellNameContent: intl.formatMessage({ id: 'common.underperformance', defaultMessage: 'Недопрацювання' }), cellValueContent: React.createElement(ValueWithCurrency, { currencySymbol: currency, value: fines === null || fines === void 0 ? void 0 : fines.lowEfficiency, separationValue: true, minimumFractionDigits: true, withZero: true }) })),
            (fines === null || fines === void 0 ? void 0 : fines.other) != null && (fines === null || fines === void 0 ? void 0 : fines.other) !== 0 && (React.createElement(DetailingRow, { cellNameContent: intl.formatMessage({ id: 'common.other', defaultMessage: 'Другое' }), cellValueContent: React.createElement(ValueWithCurrency, { currencySymbol: currency, value: fines === null || fines === void 0 ? void 0 : fines.other, separationValue: true, minimumFractionDigits: true, withZero: true }) })))),
        totalTollRoads != null && totalTollRoads !== 0 && (React.createElement(CollapsibleItem, { title: intl.formatMessage({ id: 'common.tollRoads', defaultMessage: 'Платные дороги' }), currency: currency, totalCurrency: totalTollRoads },
            tollRoads != null && tollRoads !== 0 && (React.createElement(DetailingRow, { cellNameContent: intl.formatMessage({ id: 'common.tollRoadCompensation', defaultMessage: 'Компенсація платних доріг' }), cellValueContent: React.createElement(ValueWithCurrency, { currencySymbol: currency, value: tollRoads, separationValue: true, minimumFractionDigits: true, withZero: true }) })),
            tollRoadsPayments != null && tollRoadsPayments !== 0 && (React.createElement(DetailingRow, { cellNameContent: intl.formatMessage({ id: 'common.driverTollPayment', defaultMessage: 'Оплата платних доріг водієм' }), cellValueContent: React.createElement(ValueWithCurrency, { currencySymbol: currency, value: tollRoadsPayments, separationValue: true, minimumFractionDigits: true, withZero: true }) })))),
        totalCompensation != null && totalCompensation !== 0 && (React.createElement(CollapsibleItem, { title: intl.formatMessage({ id: 'common.compensations', defaultMessage: 'Компенсації' }), currency: currency, totalCurrency: totalCompensation },
            compensation != null && compensation !== 0 && (React.createElement(DetailingRow, { cellNameContent: intl.formatMessage({ id: 'common.fleetCompensation', defaultMessage: 'Компенсація від автопарку' }), cellValueContent: React.createElement(ValueWithCurrency, { currencySymbol: currency, value: compensation, separationValue: true, minimumFractionDigits: true, withZero: true }) })),
            splitExpensesDriver != null && splitExpensesDriver !== 0 && (React.createElement(DetailingRow, { cellNameContent: intl.formatMessage({ id: 'common.driverPaidGeneralExpenses', defaultMessage: 'Общие расходы, оплаченные водителем' }), cellValueContent: React.createElement(ValueWithCurrency, { currencySymbol: currency, value: splitExpensesDriver, separationValue: true, minimumFractionDigits: true, withZero: true }) })))),
        (salary === null || salary === void 0 ? void 0 : salary.currentWeekSalaryAccruals) != null && (salary === null || salary === void 0 ? void 0 : salary.currentWeekSalaryAccruals) !== 0 && (React.createElement(CollapsibleItem, { title: intl.formatMessage({ id: 'common.salary', defaultMessage: 'Зарплата' }), currency: currency, totalCurrency: salary === null || salary === void 0 ? void 0 : salary.currentWeekSalaryAccruals },
            (salary === null || salary === void 0 ? void 0 : salary.salaryAccruals) != null && (salary === null || salary === void 0 ? void 0 : salary.salaryAccruals) !== 0 && (React.createElement(DetailingRow, { cellNameContent: intl.formatMessage({ id: 'common.salaryAccrual', defaultMessage: 'Нарахування заробітної плати' }), cellValueContent: React.createElement(ValueWithCurrency, { currencySymbol: currency, value: salary === null || salary === void 0 ? void 0 : salary.salaryAccruals, separationValue: true, minimumFractionDigits: true, withZero: true }) })),
            (salary === null || salary === void 0 ? void 0 : salary.salaryAccrualsAdjustment) != null && (salary === null || salary === void 0 ? void 0 : salary.salaryAccrualsAdjustment) !== 0 && (React.createElement(DetailingRow, { cellNameContent: intl.formatMessage({ id: 'common.salaryAccrualCorrection', defaultMessage: 'Коригування нарахування заробітної плати' }), cellValueContent: React.createElement(ValueWithCurrency, { currencySymbol: currency, value: salary === null || salary === void 0 ? void 0 : salary.salaryAccrualsAdjustment, separationValue: true, minimumFractionDigits: true, withZero: true }) })))),
        (driverPayments === null || driverPayments === void 0 ? void 0 : driverPayments.total) != null && (driverPayments === null || driverPayments === void 0 ? void 0 : driverPayments.total) !== 0 && (React.createElement(CollapsibleItem, { title: React.createElement(React.Fragment, null,
                intl.formatMessage({ id: 'common.driverPayments', defaultMessage: 'Виплати водієві' }),
                (driverPayments === null || driverPayments === void 0 ? void 0 : driverPayments.applicationPayout) ? React.createElement(TimeIcon, { width: 16, height: 16 }) : ''), currency: currency, totalCurrency: driverPayments === null || driverPayments === void 0 ? void 0 : driverPayments.total },
            (driverPayments === null || driverPayments === void 0 ? void 0 : driverPayments.cashPayment) != null && (driverPayments === null || driverPayments === void 0 ? void 0 : driverPayments.cashPayment) !== 0 && (React.createElement(DetailingRow, { cellNameContent: intl.formatMessage({ id: 'common.cashPayments', defaultMessage: 'Виплати готівкою' }), cellValueContent: React.createElement(ValueWithCurrency, { currencySymbol: currency, value: driverPayments === null || driverPayments === void 0 ? void 0 : driverPayments.cashPayment, separationValue: true, minimumFractionDigits: true, withZero: true }) })),
            (driverPayments === null || driverPayments === void 0 ? void 0 : driverPayments.commonCashlessPayments) != null && (driverPayments === null || driverPayments === void 0 ? void 0 : driverPayments.commonCashlessPayments) !== 0 && (React.createElement(DetailingRow, { cellNameContent: intl.formatMessage({ id: 'common.cashlessPayments', defaultMessage: 'Безготівкові виплати' }), cellValueContent: React.createElement(ValueWithCurrency, { currencySymbol: currency, value: driverPayments === null || driverPayments === void 0 ? void 0 : driverPayments.commonCashlessPayments, separationValue: true, minimumFractionDigits: true, withZero: true }) })),
            (driverPayments === null || driverPayments === void 0 ? void 0 : driverPayments.applicationPayout) != null && (driverPayments === null || driverPayments === void 0 ? void 0 : driverPayments.applicationPayout) !== 0 && (React.createElement(DetailingRow, { cellNameContent: intl.formatMessage({ id: 'common.pendingPayments', defaultMessage: 'Перекази в очікуванні' }), cellValueContent: React.createElement(ValueWithCurrency, { currencySymbol: currency, value: driverPayments === null || driverPayments === void 0 ? void 0 : driverPayments.applicationPayout, separationValue: true, minimumFractionDigits: true, withZero: true }) })))),
        (cashBalance === null || cashBalance === void 0 ? void 0 : cashBalance.cashHandOver) != null && (cashBalance === null || cashBalance === void 0 ? void 0 : cashBalance.cashHandOver) !== 0 && (React.createElement(CollapsibleItem, { title: intl.formatMessage({ id: 'common.cashChange', defaultMessage: 'Здача готівки' }), currency: currency, totalCurrency: cashBalance === null || cashBalance === void 0 ? void 0 : cashBalance.cashHandOver })),
        lastWeekBalance != null && lastWeekBalance !== 0 && (React.createElement(CollapsibleItem, { title: intl.formatMessage({ id: 'common.balanceFromLastWeek', defaultMessage: 'Залишок з минулого тижня' }), currency: currency, totalCurrency: lastWeekBalance })),
        React.createElement("div", { className: classes.subTitle }, intl.formatMessage({ id: 'common.balanceTitle', defaultMessage: 'Баланс' })),
        React.createElement(CollapsibleItem, { title: intl.formatMessage({ id: 'common.balanceTitle', defaultMessage: 'Баланс' }), currency: currency, totalCurrency: driverRevenueTotal })));
};
export default Balance;
