import { NetworkStatus, useQuery } from '@apollo/client';
import { Alert } from 'antd';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import classes from './Trips.module.scss';
import TripsItem from './TripsItem/TripsItem';
import Breadcrumb from '@/atoms/Breadcrumb/Breadcrumb';
import BtnBlue from '@/atoms/BtnBlue/BtnBlue';
import Preloader from '@/atoms/Preloader/Preloader';
import globalClasses from '@/containers/App/Global.module.scss';
import { LIST_TRIPS } from '@/graphql/trips';
import { routes } from '@/utils/constants';
const Trips = () => {
    var _a, _b, _c, _d;
    const intl = useIntl();
    const limit = 30;
    const { loading, error, data, fetchMore, networkStatus } = useQuery(LIST_TRIPS, {
        variables: {
            listTripsInput: {
                limit,
                offset: 0
            }
        },
        notifyOnNetworkStatusChange: true
    });
    const isFetchingMore = networkStatus === NetworkStatus.fetchMore;
    const trips = (_b = (_a = data === null || data === void 0 ? void 0 : data.listTrips) === null || _a === void 0 ? void 0 : _a.items) !== null && _b !== void 0 ? _b : [];
    const totalTrips = (_d = (_c = data === null || data === void 0 ? void 0 : data.listTrips) === null || _c === void 0 ? void 0 : _c.total) !== null && _d !== void 0 ? _d : 0;
    const handleLoadMore = useCallback(() => {
        if (!fetchMore)
            return;
        void fetchMore({
            variables: {
                listTripsInput: {
                    limit,
                    offset: trips === null || trips === void 0 ? void 0 : trips.length
                }
            },
            updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!(fetchMoreResult === null || fetchMoreResult === void 0 ? void 0 : fetchMoreResult.listTrips))
                    return prevResult;
                return {
                    listTrips: Object.assign(Object.assign({}, fetchMoreResult.listTrips), { items: [...prevResult.listTrips.items, ...fetchMoreResult.listTrips.items] })
                };
            }
        });
    }, [fetchMore, trips === null || trips === void 0 ? void 0 : trips.length, limit]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: globalClasses.topBox },
            React.createElement(Breadcrumb, { items: [{
                        id: 'home',
                        path: routes.cabinet.f,
                        text: intl.formatMessage({ id: 'common.toHome', defaultMessage: 'На головну' })
                    }] }),
            React.createElement("div", { className: globalClasses.title }, intl.formatMessage({ id: 'common.myTrips', defaultMessage: 'Мої поїздки' }))),
        React.createElement("div", { className: globalClasses.container },
            loading && (trips === null || trips === void 0 ? void 0 : trips.length) === 0 && React.createElement(Preloader, null),
            error && React.createElement(Alert, { style: { marginTop: '16px' }, message: intl.formatMessage({ id: 'error.dataLoading', defaultMessage: 'Ошибка при загрузке данных' }), type: "error" }),
            !error && (React.createElement("div", { className: classes.trips }, (trips === null || trips === void 0 ? void 0 : trips.length) > 0
                ? trips === null || trips === void 0 ? void 0 : trips.map((trip) => (React.createElement(TripsItem, { key: trip === null || trip === void 0 ? void 0 : trip.id, trip: trip })))
                : intl.formatMessage({ id: 'common.noCompletedTrips', defaultMessage: 'У вас нет совершенных поездок' }))),
            !(loading && (trips === null || trips === void 0 ? void 0 : trips.length) === 0) && (totalTrips > (trips === null || trips === void 0 ? void 0 : trips.length)) && (React.createElement(BtnBlue, { fullWidth: true, loading: isFetchingMore, onClick: handleLoadMore, text: intl.formatMessage({ id: 'common.loadMore', defaultMessage: 'Загрузить еще' }) })))));
};
export default Trips;
