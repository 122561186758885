import { gql } from '@apollo/client';
export const LIST_TRIPS = gql `
  query ListTrips($listTripsInput: ListTripsInput) {
    listTrips(listTripsInput: $listTripsInput) {
      total
      items {
        id
        cost
        currency
        paymentType
        pickUpAddress
        humanId
        destinationAddress
        completedAt
        clientName
        clientPhone
        comment
        pickUpDateTime
        car {
          licensePlate
          model
        }
      }
    }
  }
`;
