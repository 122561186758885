import { useMutation, useQuery } from '@apollo/client';
import { Alert, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getOfficeTripsDriverStatus } from './helpers/helpers';
import classes from './OfficeTrips.module.scss';
import StationItem from './StationSelection/StationItem/StationItem';
import StationSelection from './StationSelection/StationSelection';
import Breadcrumb from '@/atoms/Breadcrumb/Breadcrumb';
import BtnBlue from '@/atoms/BtnBlue/BtnBlue';
import BtnTransparent from '@/atoms/BtnTransparent/BtnTransparent';
import Preloader from '@/atoms/Preloader/Preloader';
import globalClasses from '@/containers/App/Global.module.scss';
import { GET_DRIVER_STATUS, LIST_TRIP_STATIONS, SET_STATUS_OFFLINE } from '@/graphql/officeTrips';
import useAppSelector from '@/hooks/useAppSelector';
import PlusIcon from '@/icons/plus.svg';
import StatusIcon from '@/icons/status.svg';
import { authSlice } from '@/redux/auth/reducer';
import { routes } from '@/utils/constants';
import { setAccessToken } from '@/utils/localStorage';
import { getErrorMessage } from '@/utils/validateMessages';
const OfficeTrips = () => {
    var _a, _b, _c, _d, _e;
    const locationState = useLocation();
    const navigate = useNavigate();
    const [toggleStations, setToggleStations] = useState(((_a = locationState === null || locationState === void 0 ? void 0 : locationState.state) === null || _a === void 0 ? void 0 : _a.toggleStations) || false);
    const dispatch = useDispatch();
    const { officeTripsDriverSelfBooking } = useAppSelector((state) => state.auth.settings);
    const intl = useIntl();
    const { loading: loadingListStations, error: errorListStations, data: listStations } = useQuery(LIST_TRIP_STATIONS, {
        variables: {
            listTripStationsInput: {
                offset: 0,
                limit: 50
            }
        }
    });
    const { loading, error, data } = useQuery(GET_DRIVER_STATUS);
    // TODO: put it in a hook
    useEffect(() => {
        var _a, _b;
        const errorCode = ((_b = (_a = error === null || error === void 0 ? void 0 : error.graphQLErrors[0]) === null || _a === void 0 ? void 0 : _a.extensions) === null || _b === void 0 ? void 0 : _b.code) || '';
        if (errorCode === 'BLOCKED') {
            setAccessToken(null);
            dispatch(authSlice.actions.authSetError('BLOCKED_AUTHORIZED'));
            dispatch(authSlice.actions.authSetCompanyId(''));
        }
    }, [error]);
    const [setStatusOffline] = useMutation(SET_STATUS_OFFLINE, {
        refetchQueries: () => [GET_DRIVER_STATUS, LIST_TRIP_STATIONS]
    });
    const setStatusOfflineForDriver = () => {
        setStatusOffline().then(() => {
            void message.success(intl.formatMessage({ id: 'common.statusChanged', defaultMessage: 'Статус змінено' }), 3);
        }, (err) => { getErrorMessage(err, intl); });
    };
    const openStation = () => {
        setToggleStations(true);
        navigate(locationState === null || locationState === void 0 ? void 0 : locationState.pathname, { replace: true, state: Object.assign(Object.assign({}, locationState === null || locationState === void 0 ? void 0 : locationState.state), { toggleStations: true }) });
    };
    if (loading)
        return React.createElement(Preloader, null);
    const driverStatus = data === null || data === void 0 ? void 0 : data.getDriverStatus;
    const getStationName = (_c = (_b = listStations === null || listStations === void 0 ? void 0 : listStations.listTripStations) === null || _b === void 0 ? void 0 : _b.items) === null || _c === void 0 ? void 0 : _c.find((station) => station.id === (driverStatus === null || driverStatus === void 0 ? void 0 : driverStatus.stationId));
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: globalClasses.topBox },
            React.createElement(Breadcrumb, { items: [{
                        id: 'home',
                        path: routes.cabinet.f,
                        text: intl.formatMessage({ id: 'common.toHome', defaultMessage: 'На головну' })
                    }] }),
            React.createElement("div", { className: globalClasses.title }, "Office Trips"),
            officeTripsDriverSelfBooking && (React.createElement(Link, { to: routes.createOfficeTrips.f },
                React.createElement(BtnTransparent, { white: true, icon: React.createElement(PlusIcon, null), fullWidth: true, text: intl.formatMessage({ id: 'common.createPersonalTrip', defaultMessage: 'Створити особисту поїздку' }) })))),
        error && React.createElement(Alert, { message: intl.formatMessage({ id: 'error.dataLoading', defaultMessage: 'Ошибка при загрузке данных' }), type: "error" }),
        React.createElement("div", { className: globalClasses.container }, !toggleStations && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: classes.statusBox },
                React.createElement("div", { className: `${classes.status} ${(driverStatus === null || driverStatus === void 0 ? void 0 : driverStatus.status) !== 'OFFLINE' ? classes.statusActive : ''}` },
                    React.createElement(StatusIcon, null),
                    getOfficeTripsDriverStatus((_d = driverStatus === null || driverStatus === void 0 ? void 0 : driverStatus.status) !== null && _d !== void 0 ? _d : '', intl)),
                (driverStatus === null || driverStatus === void 0 ? void 0 : driverStatus.status) === 'OFFLINE' && React.createElement(BtnBlue, { onClick: openStation, text: intl.formatMessage({ id: 'officeTrips.waitingForOrder', defaultMessage: 'Чекаю замовлення' }) }),
                (driverStatus === null || driverStatus === void 0 ? void 0 : driverStatus.status) === 'WAITING_ORDER' && React.createElement(BtnTransparent, { onClick: setStatusOfflineForDriver, text: intl.formatMessage({ id: 'common.offline', defaultMessage: 'Офлайн' }) })),
            (driverStatus === null || driverStatus === void 0 ? void 0 : driverStatus.status) === 'OFFLINE' && (React.createElement("div", { className: classes.description }, intl.formatMessage({ id: 'officeTrips.waitingForOrderDescription', defaultMessage: 'Натисніть кнопку “Чекаю замовлення”, щоб мати можливість отримати поїздку' }))),
            (driverStatus === null || driverStatus === void 0 ? void 0 : driverStatus.status) === 'WAITING_ORDER' && (React.createElement(StationItem, { station: {
                    id: driverStatus === null || driverStatus === void 0 ? void 0 : driverStatus.stationId,
                    name: getStationName ? getStationName === null || getStationName === void 0 ? void 0 : getStationName.name : driverStatus === null || driverStatus === void 0 ? void 0 : driverStatus.stationId,
                    driversCount: getStationName ? getStationName === null || getStationName === void 0 ? void 0 : getStationName.driversCount : '—',
                    address: getStationName ? getStationName === null || getStationName === void 0 ? void 0 : getStationName.address : '—'
                } }))))),
        toggleStations && React.createElement(StationSelection, { error: errorListStations, loading: loadingListStations, listStations: (_e = listStations === null || listStations === void 0 ? void 0 : listStations.listTripStations) === null || _e === void 0 ? void 0 : _e.items, setToggleStations: setToggleStations })));
};
export default OfficeTrips;
