import { Alert } from 'antd';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import React from 'react';
import { useIntl } from 'react-intl';
import Event from './Event/Event';
import FutureEvents from './FutureEvents/FutureEvents';
import classes from './MySchedule.module.scss';
import Preloader from '@/atoms/Preloader/Preloader';
import useCurrentEvent from '@/hooks/useCurrentEvent';
dayjs.extend(duration);
const calculateTimeLeft = (endDate, intl) => {
    const now = dayjs();
    const end = dayjs(endDate);
    const diff = end.diff(now);
    const durationObj = dayjs.duration(diff);
    const days = durationObj.days();
    const hours = durationObj.hours();
    const minutes = durationObj.minutes();
    const seconds = durationObj.seconds();
    return `${days} ${intl.formatMessage({ id: 'common.shortTimeDays', defaultMessage: 'дн' })}. ${hours} ${intl.formatMessage({ id: 'common.shortTimeHours', defaultMessage: 'год' })}. ${minutes} ${intl.formatMessage({ id: 'common.shortTimeMinutes', defaultMessage: 'хв' })}. ${seconds} ${intl.formatMessage({ id: 'common.shortTimeSeconds', defaultMessage: 'с' })}`;
};
const MySchedule = () => {
    var _a;
    const { eventLoading, eventError, eventData } = useCurrentEvent();
    const { currentEvent } = (_a = eventData === null || eventData === void 0 ? void 0 : eventData.getCurrentEvent) !== null && _a !== void 0 ? _a : {};
    const intl = useIntl();
    return (React.createElement("div", { className: classes.schedule },
        React.createElement("div", { className: classes.head },
            React.createElement("div", { className: classes.title }, intl.formatMessage({ id: 'common.currentEvent', defaultMessage: 'Поточна подія' }))),
        React.createElement("div", { className: classes.current },
            eventError && React.createElement(Alert, { message: intl.formatMessage({ id: 'error.dataLoading', defaultMessage: 'Ошибка при загрузке данных' }), type: "error" }),
            eventLoading && React.createElement(Preloader, { inner: true, grey: true }),
            currentEvent
                ? (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: classes.currentHead },
                        React.createElement(Event, { item: currentEvent })),
                    React.createElement("div", { className: classes.endEvent },
                        React.createElement("div", { className: classes.endEventTitle },
                            intl.formatMessage({ id: 'common.toEndEvent', defaultMessage: 'До кінця події' }),
                            ":"),
                        React.createElement("div", { className: classes.endEventValue }, calculateTimeLeft(currentEvent === null || currentEvent === void 0 ? void 0 : currentEvent.end, intl)))))
                : React.createElement("div", { style: { padding: '16px' } }, intl.formatMessage({ id: 'common.noEvent', defaultMessage: 'Нет события' }))),
        React.createElement(FutureEvents, null)));
};
export default MySchedule;
