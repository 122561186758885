import { Menu } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import classes from './MenuItems.module.scss';
// import PersonalDataIcon from '@/icons/account-circle.svg'
import useAppSelector from '@/hooks/useAppSelector';
import AccountIcon from '@/icons/account.svg';
import FinancesIcon from '@/icons/finances.svg';
import TripsIcon from '@/icons/my-trips.svg';
import OfficeTripsIcon from '@/icons/office-trips.svg';
import { routes } from '@/utils/constants';
const getItems = (officeTripsEnabled, intl) => {
    const items = [
        {
            key: 'account',
            label: React.createElement(NavLink, { to: routes.cabinet.f, className: classes.menuLink },
                React.createElement("div", { className: classes.menuIcon },
                    React.createElement(AccountIcon, null)),
                intl.formatMessage({ id: 'common.office', defaultMessage: 'Головна' }))
        },
        {
            key: 'finances',
            label: React.createElement(NavLink, { to: routes.finances.f, className: classes.menuLink },
                React.createElement("div", { className: classes.menuIcon },
                    React.createElement(FinancesIcon, null)),
                intl.formatMessage({ id: 'common.finances', defaultMessage: 'Финансы' }))
        },
        {
            key: 'trips',
            label: React.createElement(NavLink, { to: routes.trips.f, className: classes.menuLink },
                React.createElement("div", { className: classes.menuIcon },
                    React.createElement(TripsIcon, null)),
                intl.formatMessage({ id: 'common.myTrips', defaultMessage: 'Мої поїздки' }))
        }
    ];
    if (officeTripsEnabled) {
        items.push({
            key: 'officeTrips',
            label: React.createElement(NavLink, { to: routes.officeTrips.f, className: classes.menuLink },
                React.createElement("div", { className: classes.menuIcon },
                    React.createElement(OfficeTripsIcon, null)),
                "Office trips")
        });
    }
    return items;
};
const MenuItems = ({ setIsMenu }) => {
    const intl = useIntl();
    const { officeTripsEnabled } = useAppSelector((state) => state.auth.settings);
    const items = getItems(officeTripsEnabled, intl);
    const onClick = () => {
        setIsMenu(false);
    };
    return (React.createElement(Menu, { onClick: onClick, defaultSelectedKeys: ['1'], defaultOpenKeys: ['sub1'], mode: "inline", items: items }));
};
export default MenuItems;
