import React from 'react';
import { useIntl } from 'react-intl';
// import MyActs from './MyActs/MyActs'
import classes from './Cabinet.module.scss';
import MyCar from './MyCar/MyCar';
// import MyFinances from './MyFinances/MyFinances'
import MySchedule from './MySchedule/MySchedule';
import OfficeTrips from './OfficeTrips/OfficeTrips';
import globalClasses from '@/containers/App/Global.module.scss';
import useAppSelector from '@/hooks/useAppSelector';
// import InfoIcon from '@/icons/info.svg'
const Cabinet = () => {
    const intl = useIntl();
    const { officeTripsEnabled } = useAppSelector((state) => state.auth.settings);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: classes.cabinet },
            React.createElement("div", { className: globalClasses.container },
                React.createElement("div", { className: classes.title }, intl.formatMessage({ id: 'common.office', defaultMessage: 'Головна' })))),
        React.createElement("div", { className: globalClasses.container },
            React.createElement(MyCar, null),
            React.createElement(MySchedule, null),
            officeTripsEnabled && React.createElement(OfficeTrips, null))));
};
export default Cabinet;
