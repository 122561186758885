import { gql } from '@apollo/client';
export const GET_USER_INCOME = gql `
  query GetUserIncome($getUserIncomeInput: GetUserIncomeInput!) {
    getUserIncome(getUserIncomeInput: $getUserIncomeInput) {
      income {
        currency
        totalIncome
        totalTrips
        incomeWithoutSplitExpenses
        integrationDailyStatements {
          date
          vendor
          totalIncome
          totalTrips
        }
        splitExpenses {
          total
        }
      }
    }
  }
`;
export const GET_USER_REVISE = gql `
  query GetUserRevise($getUserReviseInput: GetUserReviseInput!) {
    getUserRevise(getUserReviseInput: $getUserReviseInput) {
      revise {
        currency
        tariff {
          targetMarker
        }
        splitExpenses {
          driver
        }
        cashBalance {
          cashHandOver
        }
        driverPayments {
          total
          cashPayment
          commonCashlessPayments
          applicationPayout
        }
        salary {
          currentWeekSalaryAccruals
          salaryAccruals
          salaryAccrualsAdjustment
        }
        fines {
          total
          overrun
          overspeed
          trafficRules
          downtime
          other
          firedOutDeduct
          lowEfficiency
        }
        rent {
          total
          carOverrunRentalPayment
          rentDebt
        }
        revenueByTariffRules {
          totalBonuses
          totalRevenue
          tariffTax
          accounting
          driverPayableSumsWithTariffRules {
            driverPayableSumByTariff
            tariffRule {
              from
              to
              rate
            }
          }
          fleetBonuses {
            driverBonuses
          }
        }
        integrationsRevenue {
          totalRefunds
          cashRevenue
          rewards
          tips
          byVendor {
            vendor
            cashRevenue
          }
        }
        driverRevenue {
          totalTransfers
          topUpDeposit
          deductFromDeposit
          payOffDebt
          totalTollRoads
          tollRoads
          tollRoadsPayments
          total
          lastWeekBalance
          totalCompensation
          compensation
        }
      }
    }
  }
`;
export const GET_USER_REVISE_BALANCE = gql `
  query GetUserRevise($getUserReviseInput: GetUserReviseInput!) {
    getUserRevise(getUserReviseInput: $getUserReviseInput) {
      revise {
        currency
        driverRevenue {
          total
        }
      }
    }
  }
`;
export const GET_USER_DEBT = gql `
  query GetUserDebt($getUserDebtInput: GetUserDebtInput!) {
    getUserDebt(getUserDebtInput: $getUserDebtInput) {
      debt {
        currency
        debt {
          total
          lastWeekTotalDebt
          topUpDebt
          totalPayOff
        }
      }
    }
  }
`;
export const GET_USER_DEPOSIT = gql `
  query Deposit($getUserDepositInput: GetUserDepositInput!) {
    getUserDeposit(getUserDepositInput: $getUserDepositInput) {
      deposit {
        currency
        deposit {
          total
          lastWeekTotalDeposit
          topUpDeposit
          deductFromDeposit
          payOffDebt
          totalPayments
          depositPayments
          applicationPayout
        }
      }
    }
  }
`;
