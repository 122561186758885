import { Form } from 'antd';
import IntlTelInput from 'intl-tel-input/react';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import EN from './locales/EN/index';
import PL from './locales/PL/index';
import RU from './locales/RU/index';
import TG from './locales/TG/index';
import UK from './locales/UK/index';
import UZ from './locales/UZ/index';
import useAppSelector from '@/hooks/useAppSelector';
import 'intl-tel-input/build/css/intlTelInput.css';
import { getLocale } from '@/utils/helpers';
const locales = { RU, EN, UK, PL, TG, UZ };
const PhoneMaskInput = ({ name, label, form, value, required }) => {
    const intl = useIntl();
    const { lang } = useAppSelector((state) => state.auth.user);
    const { countryCode } = useAppSelector((state) => state.auth.autoPark);
    const [phone, setPhone] = useState(value !== null && value !== void 0 ? value : '');
    const [phoneNumberUtil, setPhoneNumberUtil] = useState(null);
    const [activeLocale, setActiveLocale] = useState(getLocale(lang) || 'UK');
    useEffect(() => {
        import('google-libphonenumber')
            .then((module) => {
            setPhoneNumberUtil(module.PhoneNumberUtil.getInstance());
        })
            .catch(() => false);
    }, []);
    useEffect(() => {
        setActiveLocale(getLocale(lang));
    }, [lang]);
    const isValidPhoneNumber = (phoneNumber) => {
        if (!phoneNumberUtil)
            return false;
        try {
            const parsedPhoneNumber = phoneNumberUtil === null || phoneNumberUtil === void 0 ? void 0 : phoneNumberUtil.parse(phoneNumber);
            return phoneNumberUtil === null || phoneNumberUtil === void 0 ? void 0 : phoneNumberUtil.isValidNumber(parsedPhoneNumber);
        }
        catch (error) {
            return false;
        }
    };
    const onChange = (val) => {
        form === null || form === void 0 ? void 0 : form.setFieldsValue({ [name]: val });
        setPhone(val);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Form.Item, { name: name, normalize: (value) => (value ? value === null || value === void 0 ? void 0 : value.replace(/[^0-9+]/g, '') : value), rules: [{
                    required,
                    transform: (val) => (val ? val.replace(/[^\d+]/g, '') : val)
                },
                () => ({
                    validator(_, val) {
                        if (!val && required) {
                            return Promise.reject(new Error(intl.formatMessage({ id: 'common.enterPhone', defaultMessage: 'Введите номер телефона' })));
                        }
                        if (val) {
                            const isValid = isValidPhoneNumber(val);
                            if (!isValid) {
                                return Promise.reject(new Error(intl.formatMessage({ id: 'common.phoneError', defaultMessage: 'Введен некорректный номер телефона' })));
                            }
                        }
                        return Promise.resolve();
                    }
                })
            ], label: label },
            React.createElement(IntlTelInput, { initialValue: phone, onChangeNumber: (val) => { onChange(val); }, initOptions: {
                    i18n: locales[activeLocale],
                    nationalMode: false,
                    separateDialCode: true,
                    countryOrder: ['ua', 'pl', 'ae', 'kz', 'tr'],
                    initialCountry: countryCode || lang || 'ua',
                    loadUtilsOnInit: 'https://cdn.jsdelivr.net/npm/intl-tel-input@23.7.3/build/js/utils.js'
                } }))));
};
export default PhoneMaskInput;
