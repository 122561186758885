import { useQuery } from '@apollo/client';
import { Alert } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import CollapsibleItem from '../CollapsibleItem/CollapsibleItem';
import DetailingRow from '../DetailingRow/DetailingRow';
import classes from '../Finances.module.scss';
import Preloader from '@/atoms/Preloader/Preloader';
import ValueWithCurrency from '@/atoms/ValueWithCurrency/ValueWithCurrency';
import { GET_USER_DEPOSIT } from '@/graphql/finances';
const Deposit = ({ dateInfo }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10;
    const intl = useIntl();
    const { loading, error, data } = useQuery(GET_USER_DEPOSIT, {
        variables: {
            getUserDepositInput: {
                week: dateInfo.week,
                year: dateInfo.year
            }
        },
        skip: !dateInfo.week || !dateInfo.year
    });
    if (loading)
        return React.createElement(Preloader, { inner: true });
    if (error)
        return React.createElement("div", { style: { marginTop: 20 } },
            React.createElement(Alert, { message: intl.formatMessage({ id: 'error.dataLoading', defaultMessage: 'Ошибка при загрузке данных' }), type: "error" }));
    const currency = (_c = (_b = (_a = data === null || data === void 0 ? void 0 : data.getUserDeposit) === null || _a === void 0 ? void 0 : _a.deposit) === null || _b === void 0 ? void 0 : _b.currency) !== null && _c !== void 0 ? _c : null;
    const totalDeposit = (_g = (_f = (_e = (_d = data === null || data === void 0 ? void 0 : data.getUserDeposit) === null || _d === void 0 ? void 0 : _d.deposit) === null || _e === void 0 ? void 0 : _e.deposit) === null || _f === void 0 ? void 0 : _f.total) !== null && _g !== void 0 ? _g : null;
    const lastWeekTotalDeposit = (_l = (_k = (_j = (_h = data === null || data === void 0 ? void 0 : data.getUserDeposit) === null || _h === void 0 ? void 0 : _h.deposit) === null || _j === void 0 ? void 0 : _j.deposit) === null || _k === void 0 ? void 0 : _k.lastWeekTotalDeposit) !== null && _l !== void 0 ? _l : null;
    const topUpDeposit = (_q = (_p = (_o = (_m = data === null || data === void 0 ? void 0 : data.getUserDeposit) === null || _m === void 0 ? void 0 : _m.deposit) === null || _o === void 0 ? void 0 : _o.deposit) === null || _p === void 0 ? void 0 : _p.topUpDeposit) !== null && _q !== void 0 ? _q : null;
    const deductFromDeposit = (_u = (_t = (_s = (_r = data === null || data === void 0 ? void 0 : data.getUserDeposit) === null || _r === void 0 ? void 0 : _r.deposit) === null || _s === void 0 ? void 0 : _s.deposit) === null || _t === void 0 ? void 0 : _t.deductFromDeposit) !== null && _u !== void 0 ? _u : null;
    const payOffDebt = (_y = (_x = (_w = (_v = data === null || data === void 0 ? void 0 : data.getUserDeposit) === null || _v === void 0 ? void 0 : _v.deposit) === null || _w === void 0 ? void 0 : _w.deposit) === null || _x === void 0 ? void 0 : _x.payOffDebt) !== null && _y !== void 0 ? _y : null;
    const totalPayments = (_2 = (_1 = (_0 = (_z = data === null || data === void 0 ? void 0 : data.getUserDeposit) === null || _z === void 0 ? void 0 : _z.deposit) === null || _0 === void 0 ? void 0 : _0.deposit) === null || _1 === void 0 ? void 0 : _1.totalPayments) !== null && _2 !== void 0 ? _2 : null;
    const depositPayments = (_6 = (_5 = (_4 = (_3 = data === null || data === void 0 ? void 0 : data.getUserDeposit) === null || _3 === void 0 ? void 0 : _3.deposit) === null || _4 === void 0 ? void 0 : _4.deposit) === null || _5 === void 0 ? void 0 : _5.depositPayments) !== null && _6 !== void 0 ? _6 : null;
    const applicationPayout = (_10 = (_9 = (_8 = (_7 = data === null || data === void 0 ? void 0 : data.getUserDeposit) === null || _7 === void 0 ? void 0 : _7.deposit) === null || _8 === void 0 ? void 0 : _8.deposit) === null || _9 === void 0 ? void 0 : _9.applicationPayout) !== null && _10 !== void 0 ? _10 : null;
    return (React.createElement("div", { className: classes.income },
        React.createElement("div", { className: classes.box },
            React.createElement("div", { className: classes.boxTitle }, intl.formatMessage({ id: 'common.deposit', defaultMessage: 'Депозит' })),
            React.createElement("div", { className: classes.value },
                React.createElement(ValueWithCurrency, { currencySymbol: currency, value: totalDeposit, separationValue: true, minimumFractionDigits: true, withZero: true }))),
        lastWeekTotalDeposit != null && lastWeekTotalDeposit !== 0 && (React.createElement(CollapsibleItem, { currency: currency, totalCurrency: lastWeekTotalDeposit, title: intl.formatMessage({ id: 'common.balanceFromLastWeekWithoutPendingPayouts', defaultMessage: 'Остаток с прошлой недели без ожидающих выплат' }) })),
        topUpDeposit != null && topUpDeposit !== 0 && (React.createElement(CollapsibleItem, { currency: currency, totalCurrency: topUpDeposit, title: intl.formatMessage({ id: 'common.depositReplenishment', defaultMessage: 'Пополнение депозита' }) })),
        deductFromDeposit != null && deductFromDeposit !== 0 && (React.createElement(CollapsibleItem, { currency: currency, totalCurrency: deductFromDeposit, title: intl.formatMessage({ id: 'common.deductionFromDeposit', defaultMessage: 'Списание с депозита' }) })),
        payOffDebt != null && payOffDebt !== 0 && (React.createElement(CollapsibleItem, { currency: currency, totalCurrency: payOffDebt, title: intl.formatMessage({ id: 'common.debtRepayment', defaultMessage: 'Погашение долга' }) })),
        totalPayments != null && totalPayments !== 0 && (React.createElement(CollapsibleItem, { currency: currency, totalCurrency: totalPayments, title: intl.formatMessage({ id: 'common.driverPayments', defaultMessage: 'Выплаты водителю' }) },
            applicationPayout != null && applicationPayout !== 0 && (React.createElement(DetailingRow, { cellNameContent: intl.formatMessage({ id: 'common.paymentsPendingConfirmation', defaultMessage: 'Выплаты ожидающие подтверждения' }), cellValueContent: React.createElement(ValueWithCurrency, { currencySymbol: currency, value: applicationPayout, separationValue: true, minimumFractionDigits: true, withZero: true }) })),
            depositPayments != null && depositPayments !== 0 && (React.createElement(DetailingRow, { cellNameContent: intl.formatMessage({ id: 'common.depositPayments', defaultMessage: 'Выплаты по депозиту' }), cellValueContent: React.createElement(ValueWithCurrency, { currencySymbol: currency, value: depositPayments, separationValue: true, minimumFractionDigits: true, withZero: true }) }))))));
};
export default Deposit;
