import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import React, { useState, useEffect, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import FinishStep from './FinishStep/FinishStep';
import classes from './OfficeTrip.module.scss';
import Countdown from '@/atoms/Countdown/Countdown';
import HoldBtn from '@/atoms/HoldBtn/HoldBtn';
import ValueWithCurrency from '@/atoms/ValueWithCurrency/ValueWithCurrency';
import { GET_ACTIVE_TRIP, GET_DRIVER_STATUS, POLL_TRIP_STATE, TRIP_REQUEST_ACCEPT, TRIP_REQUEST_ARRIVE, TRIP_REQUEST_FINISH, TRIP_REQUEST_ONBOARD } from '@/graphql/officeTrips';
import usePageVisibility from '@/hooks/usePageVisibility';
import CarRideIcon from '@/icons/car-ride.svg';
import CashIcon from '@/icons/cash.svg';
import CashlessIcon from '@/icons/cashless.svg';
import FinishIcon from '@/icons/finish.svg';
import PhoneIcon from '@/icons/phone.svg';
import TimeIcon from '@/icons/time-2.svg';
import TimerIcon from '@/icons/time.svg';
import UserIcon from '@/icons/user-2.svg';
import { authSlice } from '@/redux/auth/reducer';
import { getCashType } from '@/utils/helpers';
import { setAccessToken } from '@/utils/localStorage';
import { getErrorMessage } from '@/utils/validateMessages';
const OfficeTrip = () => {
    var _a, _b, _c;
    const [currentTrip, setCurrentTrip] = useState(null);
    const [tripRequestData, setTripRequestData] = useState(false);
    const [status, setStatus] = useState('');
    const [finishData, setFinishData] = useState(null);
    const [loadingState, setLoadingState] = useState(false);
    const intl = useIntl();
    const dispatch = useDispatch();
    const visibilityTab = usePageVisibility();
    const { refetch: refetchDriverStatus } = useQuery(GET_DRIVER_STATUS, { skip: true });
    const { loading: activeTripLoading, error: activeTripError, data: activeTripData, refetch: refetchActiveTrip } = useQuery(GET_ACTIVE_TRIP);
    const [fetchTripRequest, { loading: tripRequestLoading }] = useLazyQuery(POLL_TRIP_STATE, {
        onCompleted: (data) => {
            var _a;
            const hasAssignedTrip = data === null || data === void 0 ? void 0 : data.pollTripState.hasAssignedTrip;
            setStatus((_a = data === null || data === void 0 ? void 0 : data.pollTripState) === null || _a === void 0 ? void 0 : _a.driverStatus);
            setTripRequestData(hasAssignedTrip);
        },
        onError: (error) => {
            var _a, _b;
            const errorCode = ((_b = (_a = error === null || error === void 0 ? void 0 : error.graphQLErrors[0]) === null || _a === void 0 ? void 0 : _a.extensions) === null || _b === void 0 ? void 0 : _b.code) || '';
            if (errorCode === 'BLOCKED') {
                setAccessToken(null);
                dispatch(authSlice.actions.authSetError('BLOCKED_AUTHORIZED'));
                dispatch(authSlice.actions.authSetCompanyId(''));
            }
        }
    });
    useEffect(() => {
        if (status) {
            void refetchDriverStatus();
        }
    }, [status]);
    useEffect(() => {
        const intervalId = setInterval(() => {
            if (!tripRequestLoading && !tripRequestData && !currentTrip && visibilityTab) {
                void fetchTripRequest();
            }
        }, 5000);
        return () => { clearInterval(intervalId); };
    }, [activeTripLoading, tripRequestData, fetchTripRequest, visibilityTab]);
    useEffect(() => {
        var _a, _b, _c, _d, _e;
        if (tripRequestData && !currentTrip) {
            void refetchActiveTrip();
        }
        if (activeTripData) {
            setCurrentTrip(((_a = activeTripData === null || activeTripData === void 0 ? void 0 : activeTripData.getActiveTrip) === null || _a === void 0 ? void 0 : _a.tripRequest) || null);
            setTripRequestData((_c = (_b = activeTripData === null || activeTripData === void 0 ? void 0 : activeTripData.getActiveTrip) === null || _b === void 0 ? void 0 : _b.tripRequest) === null || _c === void 0 ? void 0 : _c.id);
        }
        if (((_e = (_d = activeTripData === null || activeTripData === void 0 ? void 0 : activeTripData.getActiveTrip) === null || _d === void 0 ? void 0 : _d.tripRequest) === null || _e === void 0 ? void 0 : _e.status) === 'DRIVER_IN_ORDER_FREE') {
            setLoadingState(false);
        }
    }, [activeTripData, tripRequestData, refetchActiveTrip]);
    const [tripRequestAccept] = useMutation(TRIP_REQUEST_ACCEPT, {
        refetchQueries: () => [GET_ACTIVE_TRIP]
    });
    const [tripRequestArrive] = useMutation(TRIP_REQUEST_ARRIVE, {
        refetchQueries: () => [GET_ACTIVE_TRIP]
    });
    const [tripRequestOnboard] = useMutation(TRIP_REQUEST_ONBOARD, {
        refetchQueries: () => [GET_ACTIVE_TRIP]
    });
    const [tripRequestFinish] = useMutation(TRIP_REQUEST_FINISH, {
        refetchQueries: () => [GET_ACTIVE_TRIP]
    });
    const nextStep = useCallback(() => {
        var _a, _b, _c, _d, _e;
        const tripId = currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.id;
        const tripStatus = currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.status;
        const variables = { variables: { tripRequestId: tripId } };
        if (tripStatus === 'ASSIGNED_TO_DRIVER') {
            setLoadingState(true);
            tripRequestAccept(variables)
                .catch((err) => getErrorMessage(err, intl))
                .finally(() => { setLoadingState(false); });
        }
        if (tripStatus === 'DRIVER_IN_ORDER_FREE') {
            setLoadingState(true);
            tripRequestArrive(variables)
                .catch((err) => getErrorMessage(err, intl))
                .finally(() => { setLoadingState(false); });
        }
        if (tripStatus === 'DRIVER_ARRIVED') {
            setLoadingState(true);
            tripRequestOnboard(variables)
                .catch((err) => getErrorMessage(err, intl))
                .finally(() => { setLoadingState(false); });
        }
        if (tripStatus === 'DRIVER_IN_ORDER_BUSY') {
            setLoadingState(true);
            setFinishData({
                tripRequestId: (_a = currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.id) !== null && _a !== void 0 ? _a : null,
                type: (_b = currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.type) !== null && _b !== void 0 ? _b : null,
                paymentType: (_c = currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.paymentType) !== null && _c !== void 0 ? _c : 'UNKNOWN',
                cost: (_d = currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.cost) !== null && _d !== void 0 ? _d : 0,
                currency: (_e = currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.currency) !== null && _e !== void 0 ? _e : null
            });
            tripRequestFinish(variables).then(() => {
                setTripRequestData(false);
            }, (err) => {
                getErrorMessage(err, intl);
            }).finally(() => {
                setLoadingState(false);
            });
        }
    }, [
        intl,
        currentTrip,
        tripRequestAccept,
        tripRequestArrive,
        tripRequestOnboard,
        tripRequestFinish
    ]);
    if ((activeTripLoading || tripRequestLoading || !currentTrip) && !finishData)
        return React.createElement(React.Fragment, null);
    if (activeTripError)
        return React.createElement("p", null,
            "Error in first query: ",
            activeTripError.message);
    return (React.createElement(React.Fragment, null,
        !finishData && (React.createElement("div", { className: classes.main },
            React.createElement("div", { className: classes.overlay }),
            React.createElement("div", { className: `${(currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.status) !== 'ASSIGNED_TO_DRIVER' ? classes.full : ''} ${classes.holder}` },
                React.createElement("div", { className: classes.box },
                    (currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.status) === 'ASSIGNED_TO_DRIVER' && (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: classes.head },
                            React.createElement("div", { className: classes.title },
                                !(currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.createdByDriver) && intl.formatMessage({ id: 'officeTrips.title', defaultMessage: 'Менеджер пропонує вам поїздку типу Office trips' }),
                                (currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.createdByDriver) && intl.formatMessage({ id: 'officeTrips.youCreated', defaultMessage: 'Вы створили поїздку типу Office trips' }))),
                        React.createElement("div", { className: classes.info },
                            React.createElement("div", { className: classes.cost },
                                React.createElement(ValueWithCurrency, { value: currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.cost, currencySymbol: currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.currency, minimumFractionDigits: true, withZero: true }))))),
                    (currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.status) !== 'ASSIGNED_TO_DRIVER' && (React.createElement("div", { className: classes.steps },
                        React.createElement("div", { className: classes.stepsTitle }, intl.formatMessage({ id: 'officeTrips.officeTripsRide', defaultMessage: 'Office Trips ride' })),
                        React.createElement("div", { className: classes.stepsDescriptions }, intl.formatMessage({ id: 'officeTrips.rideStatus', defaultMessage: 'Ride status' })),
                        React.createElement("div", { className: classes.stepsProgress },
                            React.createElement("div", { className: `${(currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.status) === 'DRIVER_IN_ORDER_FREE' ? classes.active : ''} ${classes.stepsProgressItem}` },
                                React.createElement(CarRideIcon, { width: 40, height: 40 })),
                            React.createElement("div", { className: `${(currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.status) === 'DRIVER_ARRIVED' ? classes.active : ''} ${classes.stepsProgressItem}` },
                                React.createElement(TimeIcon, { width: 40, height: 40 })),
                            React.createElement("div", { className: `${(currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.status) === 'DRIVER_IN_ORDER_BUSY' ? classes.active : ''} ${classes.stepsProgressItem}` },
                                React.createElement(FinishIcon, { width: 40, height: 40 }))),
                        React.createElement("div", { className: classes.stepsStatus }, (currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.status) === 'DRIVER_IN_ORDER_FREE'
                            ? intl.formatMessage({ id: 'officeTrips.carDispatch', defaultMessage: 'Car dispatch' })
                            : (currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.status) === 'DRIVER_ARRIVED'
                                ? intl.formatMessage({ id: 'officeTrips.waitingClient', defaultMessage: 'Waiting for the client' })
                                : (currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.status) === 'DRIVER_IN_ORDER_BUSY'
                                    ? intl.formatMessage({ id: 'officeTrips.onTheWay', defaultMessage: 'On the way' })
                                    : '—'))),
                    (currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.status) === 'DRIVER_ARRIVED' && (React.createElement("div", { className: classes.timer },
                        React.createElement("div", { className: classes.timerLeft },
                            React.createElement(TimerIcon, { width: 18, height: 18 }),
                            intl.formatMessage({ id: 'officeTrips.waitingTime', defaultMessage: 'Waiting time' })),
                        React.createElement("div", { className: classes.timerRight },
                            React.createElement(Countdown, null)))),
                    React.createElement("div", { className: classes.route },
                        (currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.status) !== 'DRIVER_IN_ORDER_BUSY' && (React.createElement("div", { className: classes.routeRow },
                            React.createElement("div", { className: classes.routeLabel }, intl.formatMessage({ id: 'common.pickUpAddress', defaultMessage: 'Client pickup point' })),
                            React.createElement("div", { className: classes.routeValue }, (_a = currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.pickUpAddress) !== null && _a !== void 0 ? _a : '—'))),
                        (currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.status) === 'DRIVER_IN_ORDER_BUSY' && (React.createElement("div", { className: classes.routeRow },
                            React.createElement("div", { className: classes.routeLabel }, intl.formatMessage({ id: 'common.destinationAddress', defaultMessage: 'Адрес завершения' })),
                            React.createElement("div", { className: classes.routeValue }, (_b = currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.destinationAddress) !== null && _b !== void 0 ? _b : '—'))),
                        (currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.status) !== 'ASSIGNED_TO_DRIVER' && (React.createElement("div", { className: classes.routeRow },
                            React.createElement("div", { className: classes.routeLabel }, intl.formatMessage({ id: 'officeTrips.clientInfo', defaultMessage: 'Client Info' })),
                            React.createElement("div", { className: classes.routeClient },
                                React.createElement("div", { className: classes.routeClientLeft },
                                    React.createElement("div", { className: classes.routeClientTitle }, intl.formatMessage({ id: 'officeTrips.name', defaultMessage: 'Name' })),
                                    React.createElement("div", { className: classes.routeClientName },
                                        React.createElement(UserIcon, { width: 20, height: 20 }),
                                        (currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.clientName) || '—')),
                                (((currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.status) === 'DRIVER_IN_ORDER_FREE') || ((currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.status) === 'DRIVER_ARRIVED')) && (currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.clientPhone) && (React.createElement("a", { href: `tel:${currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.clientPhone}`, className: classes.routeClientPhone },
                                    React.createElement(PhoneIcon, { width: 24, height: 24 })))))),
                        (currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.status) === 'DRIVER_IN_ORDER_BUSY' && (React.createElement("div", { className: classes.routeRow },
                            React.createElement("div", { className: classes.routeLabel }, intl.formatMessage({ id: 'officeTrips.paymentType', defaultMessage: 'Payment' })),
                            React.createElement("div", { className: classes.cashType },
                                (currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.paymentType) === 'CASH' ? React.createElement(CashIcon, { width: 24, height: 24 }) : React.createElement(CashlessIcon, { width: 24, height: 24 }), (_c = getCashType(currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.paymentType, intl)) !== null && _c !== void 0 ? _c : '—'))))),
                React.createElement("div", { className: classes.btnBox },
                    React.createElement(HoldBtn, { holdEvent: nextStep, loadingState: loadingState }, (currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.status) === 'ASSIGNED_TO_DRIVER'
                        ? intl.formatMessage({ id: 'common.acceptOrder', defaultMessage: 'Удерживайте, чтобы принять заказ' })
                        : (currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.status) === 'DRIVER_IN_ORDER_FREE'
                            ? intl.formatMessage({ id: 'officeTrips.arrived', defaultMessage: 'Удерживайте по прибытии' })
                            : (currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.status) === 'DRIVER_ARRIVED'
                                ? intl.formatMessage({ id: 'officeTrips.startRide', defaultMessage: 'Удерживайте, чтобы начать поездку' })
                                : (currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.status) === 'DRIVER_IN_ORDER_BUSY'
                                    ? intl.formatMessage({ id: 'officeTrips.swipeFinish', defaultMessage: 'Удерживайте, чтобы завершить поездку' })
                                    : '—'))))),
        finishData && React.createElement(FinishStep, { finishData: finishData, setFinishData: setFinishData })));
};
export default OfficeTrip;
