import React, { useState, useCallback, useMemo } from 'react';
import classes from './CollapsibleItem.module.scss';
import ValueWithCurrency from '@/atoms/ValueWithCurrency/ValueWithCurrency';
import globalClasses from '@/containers/App/Global.module.scss';
const CollapsibleItem = ({ title, totalValue, totalCurrency, currency, children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const hasValidChildren = useMemo(() => {
        if (!children)
            return false;
        if (Array.isArray(children)) {
            return children.some((child) => child !== false && child != null);
        }
        return true;
    }, [children]);
    const toggleOpen = useCallback(() => {
        if (hasValidChildren) {
            setIsOpen((prev) => !prev);
        }
    }, [hasValidChildren]);
    return (React.createElement("div", { className: classes.collapsible },
        React.createElement("div", { className: classes.collapsibleHeader },
            React.createElement("div", { className: classes.collapsibleTitle }, title),
            React.createElement("div", { className: classes.collapsibleHeaderRight, onClick: toggleOpen },
                totalValue != null && React.createElement("span", null, totalValue),
                totalCurrency != null && (React.createElement("span", null,
                    React.createElement(ValueWithCurrency, { currencySymbol: currency, value: totalCurrency, minimumFractionDigits: true, withZero: true }))),
                hasValidChildren && (React.createElement("div", { className: `${globalClasses.openCloseBtn} ${isOpen ? globalClasses.open : ''}` })))),
        isOpen && hasValidChildren && (React.createElement("div", { className: classes.collapsibleContent }, children))));
};
export default CollapsibleItem;
