import { useMutation } from '@apollo/client';
import { Form, Input } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import classes from './ChangeCost.module.scss';
import BtnBlue from '@/atoms/BtnBlue/BtnBlue';
import BtnTransparent from '@/atoms/BtnTransparent/BtnTransparent';
import globalClasses from '@/containers/App/Global.module.scss';
import { TRIPS_REQUEST_COMPLAINT } from '@/graphql/officeTrips';
import { getErrorMessage } from '@/utils/validateMessages';
const ChangeCost = ({ finishData, setLastStep, setIsChangeCost }) => {
    const [loadingState, setLoadingState] = useState(false);
    const intl = useIntl();
    const [tripRequestComplaint] = useMutation(TRIPS_REQUEST_COMPLAINT);
    const onFinish = (values) => {
        setLoadingState(true);
        const { costRequested, comment } = values;
        tripRequestComplaint({
            variables: {
                tripRequestComplaintInput: Object.assign({ tripRequestId: finishData === null || finishData === void 0 ? void 0 : finishData.tripRequestId, costRequested: +costRequested }, comment && { comment })
            }
        }).then(() => {
            setIsChangeCost(false);
            setLastStep(true);
        }, (err) => {
            getErrorMessage(err, intl);
        }).finally(() => { setLoadingState(false); });
    };
    return (React.createElement("div", { className: classes.changeCost },
        React.createElement(Form, { autoComplete: "off", name: "createTripRequest", onFinish: onFinish, scrollToFirstError: true },
            React.createElement(Form.Item, { label: intl.formatMessage({ id: 'common.enterCost', defaultMessage: 'Введіть вартість' }), name: "costRequested", normalize: value => (value.replace(/[^0-9.]/g, '').match(/^\d*(\.\d{0,2})?/g) || [''])[0], rules: [{ required: true, message: intl.formatMessage({ id: 'common.enterCost', defaultMessage: 'Введіть вартість' }) }] },
                React.createElement(Input, { placeholder: intl.formatMessage({ id: 'common.enterCost', defaultMessage: 'Введіть вартість' }) })),
            React.createElement(Form.Item, { label: intl.formatMessage({ id: 'common.comment', defaultMessage: 'Коментар' }), name: "comment" },
                React.createElement(TextArea, { placeholder: intl.formatMessage({ id: 'common.enterComment', defaultMessage: 'Введіть коментар' }) })),
            React.createElement("div", { className: globalClasses.controlsFixed },
                React.createElement(BtnTransparent, { onClick: () => { setIsChangeCost(false); }, type: 'button', text: intl.formatMessage({ id: 'common.cancel', defaultMessage: 'Скасувати' }) }),
                React.createElement(BtnBlue, { loading: loadingState, type: "submit", text: intl.formatMessage({ id: 'common.change', defaultMessage: 'Изменить' }) })))));
};
export default ChangeCost;
