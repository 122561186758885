import dayjs from 'dayjs';
import React, { useState, useCallback, memo } from 'react';
import { useIntl } from 'react-intl';
import classes from './TripsItem.module.scss';
import ValueWithCurrency from '@/atoms/ValueWithCurrency/ValueWithCurrency';
import useAppSelector from '@/hooks/useAppSelector';
import ArrowIcon from '@/icons/arrows-down.svg';
import CarIcon from '@/icons/car.svg';
import CashIcon from '@/icons/cash.svg';
import CashlessIcon from '@/icons/cashless.svg';
import CopyIcon from '@/icons/copy.svg';
import PhoneIcon from '@/icons/phone-2.svg';
import UserIcon from '@/icons/user-3.svg';
import 'dayjs/locale/en';
import 'dayjs/locale/pl';
import 'dayjs/locale/ru';
import 'dayjs/locale/tg';
import 'dayjs/locale/uk';
import 'dayjs/locale/uz';
import { copyToClipboard, getCashType, getLocale } from '@/utils/helpers';
const TripsItemComponent = ({ trip }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    const [isOpen, setIsOpen] = useState(false);
    const intl = useIntl();
    const { lang } = useAppSelector((state) => state.auth.user);
    const locale = getLocale(lang).toLocaleLowerCase() || 'en';
    dayjs.locale(locale);
    const toggleOpen = useCallback(() => {
        setIsOpen(prev => !prev);
    }, []);
    const pickUpDateTime = dayjs(trip === null || trip === void 0 ? void 0 : trip.pickUpDateTime);
    const completedAt = dayjs(trip === null || trip === void 0 ? void 0 : trip.completedAt);
    const date = pickUpDateTime.isSame(completedAt, 'day')
        ? `${pickUpDateTime.format('D MMMM YYYY')},\n${pickUpDateTime.format('HH:mm')} - ${completedAt.format('HH:mm')}`
        : `${pickUpDateTime.format('D MMMM YYYY, HH:mm')}\n${completedAt.format('D MMMM YYYY, HH:mm')}`;
    return (React.createElement("div", { className: classes.trip },
        React.createElement("div", { className: classes.tripHead },
            React.createElement("div", { className: classes.tripTop },
                React.createElement("div", { className: classes.tripService }, "Office trip"),
                React.createElement("div", { className: classes.tripDate }, date !== null && date !== void 0 ? date : '—')),
            React.createElement("div", { className: classes.tripPaymentInformation },
                React.createElement("div", { className: classes.tripPaymentCell },
                    React.createElement(ValueWithCurrency, { currencySymbol: (_a = trip === null || trip === void 0 ? void 0 : trip.currency) !== null && _a !== void 0 ? _a : null, value: trip === null || trip === void 0 ? void 0 : trip.cost, minimumFractionDigits: true, withZero: true })),
                React.createElement("div", { className: classes.tripPaymentCell },
                    (trip === null || trip === void 0 ? void 0 : trip.paymentType) === 'CASH' ? React.createElement(CashIcon, { width: 24, height: 24 }) : React.createElement(CashlessIcon, { width: 24, height: 24 }), (_b = getCashType(trip === null || trip === void 0 ? void 0 : trip.paymentType, intl)) !== null && _b !== void 0 ? _b : '—')),
            !isOpen && (React.createElement("div", { className: classes.tripPickUpAddress },
                React.createElement("div", { className: classes.tripPickUpAddressTitle }, intl.formatMessage({ id: 'common.pickUpAddress', defaultMessage: 'Адрес посадки' })),
                React.createElement("div", { className: classes.address }, (_c = trip === null || trip === void 0 ? void 0 : trip.pickUpAddress) !== null && _c !== void 0 ? _c : '—')))),
        isOpen && (React.createElement("div", { className: classes.tripContent },
            React.createElement("div", { className: classes.route },
                React.createElement("div", { className: classes.routeTitle }, intl.formatMessage({ id: 'common.route', defaultMessage: 'Маршрут' })),
                React.createElement("div", { className: classes.routeList },
                    React.createElement("div", { className: classes.routeItem },
                        React.createElement("div", { className: classes.address }, (_d = trip === null || trip === void 0 ? void 0 : trip.pickUpAddress) !== null && _d !== void 0 ? _d : '—')),
                    React.createElement("div", { className: classes.routeItem },
                        React.createElement("div", { className: classes.address }, (_e = trip === null || trip === void 0 ? void 0 : trip.destinationAddress) !== null && _e !== void 0 ? _e : '—')))),
            (trip === null || trip === void 0 ? void 0 : trip.car) && (React.createElement("div", { className: classes.tripBox },
                React.createElement("div", { className: classes.tripBoxIcon },
                    React.createElement(CarIcon, null)),
                React.createElement("div", { className: classes.tripBoxHolder },
                    React.createElement("div", { className: classes.tripBoxTitle }, intl.formatMessage({ id: 'common.car', defaultMessage: 'Авто' })),
                    React.createElement("div", { className: classes.tripBoxRow },
                        React.createElement("div", { className: classes.tripBoxRowValue }, (_g = (_f = trip === null || trip === void 0 ? void 0 : trip.car) === null || _f === void 0 ? void 0 : _f.licensePlate) !== null && _g !== void 0 ? _g : '—'),
                        ((_h = trip === null || trip === void 0 ? void 0 : trip.car) === null || _h === void 0 ? void 0 : _h.model) && React.createElement("div", { className: classes.tripBoxRowDescription }, (_j = trip === null || trip === void 0 ? void 0 : trip.car) === null || _j === void 0 ? void 0 : _j.model))))),
            React.createElement("div", { className: classes.order },
                React.createElement("div", { className: classes.orderRow },
                    React.createElement("div", { className: classes.orderTitle }, intl.formatMessage({ id: 'common.bookingNumber', defaultMessage: 'Номер бронирования' })),
                    React.createElement("div", { className: classes.orderNumber }, (_k = trip === null || trip === void 0 ? void 0 : trip.humanId) !== null && _k !== void 0 ? _k : '—'),
                    React.createElement("div", { className: classes.orderBtn, onClick: () => { var _a; copyToClipboard(intl, (_a = trip === null || trip === void 0 ? void 0 : trip.humanId) !== null && _a !== void 0 ? _a : '—'); } },
                        React.createElement(CopyIcon, null)))),
            (trip === null || trip === void 0 ? void 0 : trip.clientName) && (React.createElement("div", { className: classes.tripBox },
                React.createElement("div", { className: classes.tripBoxIcon },
                    React.createElement(UserIcon, null)),
                React.createElement("div", { className: classes.tripBoxHolder },
                    React.createElement("div", { className: classes.tripBoxTitle }, intl.formatMessage({ id: 'officeTrips.clientName', defaultMessage: 'Клієнт' })),
                    React.createElement("div", { className: classes.tripBoxRow },
                        React.createElement("div", { className: classes.tripBoxRowValue }, (_l = trip === null || trip === void 0 ? void 0 : trip.clientName) !== null && _l !== void 0 ? _l : '—'))))),
            (trip === null || trip === void 0 ? void 0 : trip.clientPhone) && (React.createElement("div", { className: classes.tripBox },
                React.createElement("div", { className: classes.tripBoxIcon },
                    React.createElement(PhoneIcon, null)),
                React.createElement("div", { className: classes.tripBoxHolder },
                    React.createElement("div", { className: classes.tripBoxTitle }, intl.formatMessage({ id: 'common.clientPhone', defaultMessage: 'Телефон клиента' })),
                    React.createElement("div", { className: classes.tripBoxRow },
                        React.createElement("div", { className: classes.tripBoxRowValue },
                            React.createElement("a", { href: `tel:${trip === null || trip === void 0 ? void 0 : trip.clientPhone}`, className: classes.routeClientPhone }, trip === null || trip === void 0 ? void 0 : trip.clientPhone)))))))),
        React.createElement("div", { className: classes.tripBtn, onClick: toggleOpen },
            isOpen
                ? intl.formatMessage({ id: 'common.less', defaultMessage: 'Менше' })
                : intl.formatMessage({ id: 'common.showMore', defaultMessage: 'Більше' }),
            React.createElement(ArrowIcon, { className: isOpen ? classes.open : '' }))));
};
const TripsItem = memo(TripsItemComponent);
TripsItem.displayName = 'TripsItem';
export default TripsItem;
