import { Alert } from 'antd';
import dayjs from 'dayjs';
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import Event from '../Event/Event';
import classes from '../MySchedule.module.scss';
import Preloader from '@/atoms/Preloader/Preloader';
import useListSchedule from '@/hooks/useListSchedule';
const FutureEvents = () => {
    var _a, _b;
    const intl = useIntl();
    const sinceDate = useMemo(() => dayjs().toISOString(), []);
    const [limit, setLimit] = useState(5);
    const { listScheduleData, listScheduleLoading, listScheduleError } = useListSchedule({
        limit,
        filters: { sinceDate },
        ordering: { start: 'ASC' }
    });
    const getMoreEvents = () => {
        setLimit(limit + 5);
    };
    const events = (_b = (_a = listScheduleData === null || listScheduleData === void 0 ? void 0 : listScheduleData.listSchedule) === null || _a === void 0 ? void 0 : _a.items) !== null && _b !== void 0 ? _b : [];
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: classes.head },
            React.createElement("div", { className: classes.title }, intl.formatMessage({ id: 'common.futureEvents', defaultMessage: 'Майбутні події' }))),
        React.createElement("div", { className: classes.eventsListHolder },
            React.createElement("div", { className: classes.eventsList },
                listScheduleError && React.createElement(Alert, { message: intl.formatMessage({ id: 'error.dataLoading', defaultMessage: 'Ошибка при загрузке данных' }), type: "error" }),
                listScheduleLoading && React.createElement(Preloader, { inner: true }),
                events.length
                    ? events.map((event) => (React.createElement(Event, { key: event.id, item: event })))
                    : React.createElement("div", { style: { padding: '16px' } }, intl.formatMessage({ id: 'common.noEvents', defaultMessage: 'Немає подій' }))),
            events.length > 0 && React.createElement("div", { className: classes.more, onClick: getMoreEvents }, intl.formatMessage({ id: 'common.more', defaultMessage: 'Показати більше' })))));
};
export default FutureEvents;
