import { gql } from '@apollo/client';
export const SIGN_UP_BY_INVITE = gql `
  mutation SignUpByInvite($signUpByInviteInput: SignUpByInviteInput!) {
    signUpByInvite(signUpByInviteInput: $signUpByInviteInput) {
      jwtToken
    }
  }
`;
export const RESET_PASSWORD = gql `
  mutation ResetPassword($resetPasswordInput: ResetPasswordInput!) {
    resetPassword(resetPasswordInput: $resetPasswordInput) {
      jwtToken
    }
  }
`;
export const SING_IN = gql `
  mutation Mutation($signInInput: SignInInput!) {
    signIn(signInInput: $signInInput) {
      jwtToken
    }
  }
`;
export const GET_USER = gql `
  query Me {
    me {
      user {
        id
        companyId
        firstName
        lastName
        phone
        lang
        avatarUrl
      }
      autoPark {
        countryCode
      }
      settings {
        officeTripsEnabled
        officeTripsDriverSelfBooking
        balanceDetails
        debtDetails
        depositDetails
        carTransferAcceptance {
          fieldSettings {
            field
            demand
          }
        }
      }
    }
  }
`;
export const EDIT_USER_LANG = gql `
  mutation EditUser($editUserInput: EditUserInput!) {
    editUser(editUserInput: $editUserInput) {
      lang
    }
  }
`;
export const CHECK_RESET_PASSWORD = gql `
  query CheckResetPasswordId($checkResetPasswordIdInput: CheckResetPasswordIdInput!) {
    checkResetPasswordId(checkResetPasswordIdInput: $checkResetPasswordIdInput) {
      valid
    }
  }
`;
